import React, {Component} from 'react';
import RangeBar from "../../components/charts/atoms/RangeBar";

class AgeRangeWidget extends Component {

    render() {
        return (
            <div className='bg-white flex-[1.2] rounded-lg shadow-lg flex flex-col p-6 gap-3'>
                <p className='text-gray-800 text-2xl font-bold'>
                    Donor Age Range
                </p>
                <div className='flex flex-col gap-3 flex-1 justify-around'>
                    {this.props.data.map(item =>
                        <RangeBar
                        label={item.label}
                        pct={item.value}
                    />
                    )}
                </div>
            </div>
        );
    }
}

export default AgeRangeWidget;
