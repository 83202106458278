import React, {Component} from "react";
import {Button} from "../components/atoms/buttons/Button";
import {logout} from "../api/authentication";
import {Link} from "react-router-dom";

class DefaultPage extends Component {
    render() {
        return (
            <div className='w-full h-[100vh] flex flex-col items-center justify-center bg-brand'>
                <div className='burst'/>
                <div className='burst2'/>
                <div className='mb-5'>
                    <img className='w-40' src={require('../img/brand/full_logo.png')}/>
                </div>
                <div className='flex flex-col bg-white z-[2] shadow-2xl p-8 rounded-2xl mb-5 w-[380px]'>
                    <p className={'heading3 text-gray-800'}>
                        Oh no!
                    </p>
                    <p className='body1 text-gray-500'>
                        This account doesn't have admin access to any nonprofits. If you have already submitted a claim please wait 2 business days before reaching out.
                    </p>
                    <p className='body1 text-gray-500'>
                        Otherwise, if you think this is a mistake. Email us at <u>hello@starfishapp.com</u>.
                    </p>
                    <div className='w-full flex-1 flex-col flex items-stretch justify-stretch'>
                        {/* <div className='flex flex-row justify-center items-center w-full'> */}
                        <Link to={'/claim'} className='w-full flex-1 flex flex-col'>
                            <Button variant={'default'} className='mt-10 w-full' >
                                Claim a Nonprofit
                            </Button>
                        </Link>
                        {/* </div> */}
                        <Button variant={'secondary'} onClick={logout} className='mt-2'>
                            Log Out
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default DefaultPage;
