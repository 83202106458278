import React, {Component} from 'react';
import {VictoryAxis, VictoryChart, VictoryLegend, VictoryLine} from 'victory';
import {roundTo} from "../../utils/strings";
import { format } from 'date-fns';


class LineChart extends Component {  
    render() {
        const totalLineData = this.props.data.total_line.map(item => ({ x: new Date(item.x), y: item.y }));
        const recurringLineData = this.props.data.recurring_line.map(item => ({ x: new Date(item.x), y: item.y }));
        return (
            <VictoryChart
                domainPadding={{x: [16, 16], y: [16, 0]}} // Override domain padding
                padding={{ top: 16, bottom: 32, left: 32, right: 32 }} // Override padding
                height={200}
            >
                <VictoryAxis
                    dependentAxis
                    tickFormat={(tick) => `$${roundTo(tick, 0)}`}
                    style={{
                        axis: {stroke: "transparent"},
                        ticks: {stroke: "#00000050"},
                        tickLabels: {fontSize: 8, padding: 4, fill: '#00000050'},
                        grid: {stroke: '#00000015', strokeWidth: 0.5},
                    }}
                />
                <VictoryAxis
                    tickFormat={(tick) => format(tick, 'MM/dd/yyyy')}
                    style={{
                        axis: {stroke: "#00000030"},
                        ticks: {stroke: "#00000010"},
                        tickLabels: {fontSize: 8, padding: 4, fill: '#00000050'},
                    }}
                />
                <VictoryLine
                    data={totalLineData}
                    style={{
                        data: {stroke: "#1662F5"},
                    }}
                    interpolation={'basis'}
                />
                <VictoryLine
                    data={recurringLineData}
                    style={{
                        data: {stroke: "#F8969C"},
                    }}
                    interpolation={'basis'}
                />
            </VictoryChart>
        );
    }
}

export default LineChart;
