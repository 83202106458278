import React, {Component} from 'react';
import AgeRangeWidget from "../components/widgets/AgeRangeWidget";
import DonutChartWidget from "../components/widgets/DonutChartWidget";
import HeaderStatsWidget from "../components/widgets/HeaderStatsWidget";
import LineChartWidget from "../components/widgets/LineChartWidget";
import BarChartWidget from "../components/widgets/BarChartWidget";
import ProfileOverviewWidget from "../components/widgets/ProfileOverviewWidget";
import DonationTableWidget from "../components/widgets/DonationTableWidget";
import ActivityTableWidget from "../components/widgets/ActivityTableWidget";
import Spinner from '../components/Spinner';
import {getDashboard, getSocialActivity, getDashboardOverview, getDashboardHeader, getDashboardCharts, getDashboardHistory} from '../api/dashboard';
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        selectedNonProfit: state.userReducer.selectedNonProfit,
    }
}


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingOverview: false,
            loadingHeader: false,
            loadingCharts: false,
            loadingHistory: false,
            loadingActivity: false,
            nonProfits: [],
            profileOverview: {},
            headerStats: {},
            donationCharts: {'donation_timeline': {
                                'total_line': [], 'recurring_line': []},
                                'donor_age_ranges': [],
                                'challenge_v_direct': [{'x': ''}, {'x': ''}]
                            },
            donationHistory: [],
            socialActivity: [],
            // @Strachan if you'd rather fetch with one call:
            // nonProfitData:
            //     {
            //         profile_overview: {},
            //         header_stats: {},
            //         donation_charts: {'donation_timeline': {
            //                             'total_line': [], 'recurring_line': []},
            //                             'donor_age_ranges': [],
            //                             'challenge_v_direct': [{'x': ''}, {'x': ''}]
            //                         },
            //         donation_history: []
            //     },
            selectedNonProfit: '',
        }
    }


    componentDidMount = async () => {
        this.getDashboardData();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedNonProfit !== prevProps.selectedNonProfit) {
            this.getDashboardData();
        }
    }

    getDashboardData = async () => {
        this.setState({loadingOverview: true, loadingHeader: true, loadingCharts: true, loadingHistory: true, loadingActivity: true});
        const profileOverview = await getDashboardOverview(this.props.user, this.props.selectedNonProfit);
        this.setState({profileOverview: profileOverview.result, loadingOverview: false})
        const headerStats = await getDashboardHeader(this.props.user, this.props.selectedNonProfit);
        this.setState({headerStats: headerStats.result, loadingHeader: false})
        const donationCharts = await getDashboardCharts(this.props.user, this.props.selectedNonProfit);
        this.setState({donationCharts: donationCharts.result, loadingCharts: false})
        const donationHistory = await getDashboardHistory(this.props.user, this.props.selectedNonProfit);
        this.setState({donationHistory: donationHistory.result, loadingHistory: false})
        const res = await getSocialActivity(this.props.user, this.props.selectedNonProfit);
        this.setState({socialActivity: res.activity, loadingActivity: false})

        // @Strachan if you'd rather fetch with one call
        // const nonProfitData = await getDashboard(this.props.user, this.props.selectedNonProfit);
        // this.setState({nonProfitData: nonProfitData.result, loading: false})
    }


    render() {
        if (this.state.loadingOverview) {
            return (
                <div className="h-[100vh] flex flex-col items-center justify-center">
                    <Spinner size={40}/>
                    <p className='text-sm mt-5 text-slate-500'>
                        Loading dashboard
                    </p>
                </div>
            )
        }
        return (
            <div>
                <div className='page-container relative pt-20 px-4 bg-slate-100 flex flex-col gap-5 pb-5'>
                    <div className='flex flex-col items-stretch gap-5'>
                        <ProfileOverviewWidget data={this.state.profileOverview}/>
                        {this.state.loadingHeader ? 
                        <div className='flex flex-col items-center'>
                            <Spinner size={80}/>
                            <p className='text-sm mt-5 text-slate-500'>
                                Loading Summary
                            </p>
                        </div>
                        :
                        <HeaderStatsWidget data={this.state.headerStats}/>
                        }
                    </div>
                    {this.state.loadingCharts ?
                    <div className='flex flex-col items-center'>
                        <Spinner size={80}/>
                        <p className='text-sm mt-5 text-slate-500'>
                            Loading Charts
                        </p>
                    </div>
                    :
                    <div className='grid grid-cols-2 gap-5'>
                        <LineChartWidget data={this.state.donationCharts.donation_timeline}/>
                        <div className='grid grid-cols-2 gap-5'>
                            <AgeRangeWidget data={this.state.donationCharts.donor_age_ranges}/>
                            <DonutChartWidget
                                title={'Challenge $ vs Direct $'}
                                data={this.state.donationCharts.challenge_v_direct}
                            />
                        </div>
                    </div>
                    }
                    {this.state.loadingHistory ?
                    <div className='flex flex-col items-center'>
                        <Spinner size={80}/>
                        <p className='text-sm mt-5 text-slate-500'>
                            Loading History
                        </p>
                    </div>
                    :
                    <div className='flex flex-col flex-1 gap-5'>
                        <DonationTableWidget data={this.state.donationHistory}/>
                    </div>
                    }
                    {this.state.loadingActivity?
                    <div className='flex flex-col items-center'>
                        <Spinner size={80}/>
                        <p className='text-sm mt-5 text-slate-500'>
                            Loading Activity
                        </p>
                    </div>
                    :
                    <div className='flex flex-col flex-1 gap-5'>
                        <ActivityTableWidget data={this.state.socialActivity}/>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Home);
