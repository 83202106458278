import axios from "./axios";

export const getConnectedAccount = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/stripe/connect", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createConnectedAccount = async (user, ein = null, accountNumber) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {ein, account_number: accountNumber}
        const res = await axios.post("/stripe/connect", body, config);
        console.log(res.data)
        window.location = res.data.account_link_url?.url
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteConnectedAccount = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.delete("/stripe/connect", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getNonProfitBalance = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/funding/balance", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
