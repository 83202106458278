import React, {Component} from 'react';
import RangeBar from "../../components/charts/atoms/RangeBar";
import DonutChart from "../charts/DonutChart";


class DonationTableWidget extends Component {

    getStatusColor = (status) => {
        switch (status) {
            case 'Pending':
                return 'text-yellow-500 bg-yellow-50';
            case 'Approved':
                return 'text-green-500 bg-green-50';
            case 'Cancelled':
                return 'text-red-500 bg-red-50';
            default:
                return 'text-gray-500 bg-gray-50';
        }
    };

    getTypeColor = (status) => {
        switch (status) {
            case 'Direct':
                return 'text-gray-500 bg-gray-50';
            case 'Challenge':
                return 'text-blue-500 bg-blue-50';
            default:
                return 'text-gray-500 bg-gray-50';
        }
    };

    render() {

        return (
            <div className='bg-white rounded-lg shadow-lg p-6 flex flex-col gap-3'>
                <p className='text-gray-800 text-2xl font-bold'>
                    Donation History
                </p>
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-row flex-1 border-b border-gray-200 pb-2'>
                        <div className='flex-[1]'>
                            <p className='text-xs text-gray-500'>
                                Name
                            </p>
                        </div>
                        <div className='flex-[1]'>
                            <p className='text-xs text-gray-500'>
                                Date
                            </p>
                        </div>
                        <div className='flex-[1]'>
                            <p className='text-xs text-gray-500'>
                                Amount
                            </p>
                        </div>
                        <div className='flex-[1]'>
                            <p className='text-xs text-gray-500'>
                                Type
                            </p>
                        </div>
                        <div className='flex-[1]'>
                            <p className='text-xs text-gray-500'>
                                Status
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        {this.props.data.map((item) => (
                            <div key={item.id} className='flex flex-row flex-1 pb-2 last:pb-0 border-b border-gray-100 last:border-0'>
                                <div className='flex-[1]'>
                                    <p className='text-sm font-semibold text-gray-800'>{item.name}</p>
                                </div>
                                <div className='flex-[1]'>
                                    <p className='text-sm font-semibold text-gray-500'>{item.date}</p>
                                </div>
                                <div className='flex-[1]'>
                                    <p className='text-sm font-semibold text-gray-500'>${item.amount}</p>
                                </div>
                                <div className='flex-[1] flex flex-row justify-start'>
                                    <div className={`${this.getTypeColor(item.type)} px-2 py-0.5 rounded-full`}>
                                        <p className={`text-sm font-bold`}>
                                            {item.type}
                                        </p>
                                    </div>
                                </div>
                                <div className='flex-[1] flex flex-row justify-start'>
                                    <div className={`${this.getStatusColor(item.status)} px-2 py-0.5 rounded-full`}>
                                        <p className={`text-sm font-bold`}>
                                            {item.status}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {this.props.data.length === 0 && (
                            <div className='flex flex-row flex-1 pb-2  mt-4 mb-4'>
                                <div className='flex-[1] text-center'>
                                    <p className='text-sm font-semibold text-gray-800'>No previous donations</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default DonationTableWidget;
