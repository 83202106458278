import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Home from "./pages/Home";
import './App.css';
import './styles/Formatting.css';
import './styles/ColorStyles.css'
import './styles/Inputs.css'
import './styles/Navbar.css'
import './styles/TextStyles.css'
import './styles/ButtonStyles.css'
import './styles/Components.css'
import './styles/Gallery.css'
import './styles/ModalComponent.css'
import './styles/Tailwinds-Config.css';


import './App.css'
import Navbar from "./components/Navbar";
import UserProvider, {UserContext} from "./providers/UserProvider";
import Routes from "./Routes";
import {Provider} from "react-redux";
import Store from "./Store";

class App extends Component {

    render() {
        return (
            <div className='relative'>
                <UserProvider>
                    <Router>
                        <Provider store={Store}>
                            <UserContext.Consumer>
                                {(user) => (
                                    <Routes user={user}/>
                                )}
                            </UserContext.Consumer>
                        </Provider>
                    </Router>
                </UserProvider>
            </div>
        );
    }
}

export default App
