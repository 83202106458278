import React, {Component} from 'react';
import gif from '../img/bouncy-a-paper-airplane-with-a-check-mark.gif'

class ClaimNPOComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bio: "",
        };
    }

    bioChanged = e => this.setState({bio: e.target.value});

    render() {
        return (
            <div className='flex flex-col gap-3 items-center text-center'>
                <img alt={'done'} src={gif} className='w-1/2'/>
                <p className='text-gray-800 text-2xl font-bold'>
                    Request Complete!
                </p>
                <p className='text-md text-slate-500 mb-5'>
                    We'll get back to you with status updates as soon as we can. Thanks for choosing Starfish! If you
                    have any other
                    questions, reach out to
                    <a href="mailto:hello@starfishapp.com" className="text-blue-500"> hello@starfishapp.com</a> with any
                    questions.
                </p>
            </div>
        );
    }
}

export default ClaimNPOComplete;
