// WithNav.js (Stand-alone Functional Component)
import React from 'react';
import {Outlet} from 'react-router-dom';
import Navbar from "../Navbar";


const WithoutNav = ({client}) => {
    return (
        <>
            <div>
                <Outlet/>
            </div>
        </>
    );
};


export default WithoutNav;
