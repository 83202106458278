import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-plus-circle"
            {...props}
        >
            <circle cx={12} cy={12} r={10} />
            <path d="M12 8L12 16" />
            <path d="M8 12L16 12" />
        </svg>
    )
}

export default SvgComponent
