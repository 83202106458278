import React, {Component} from 'react';
import Navbar from "../components/Navbar";
import Spinner from '../components/Spinner';
import {getNonProfits} from "../api/dashboard";
import { getNonProfit } from '../api/nonProfits';
import EditAccountWidget from "../components/widgets/EditAccountWidget";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        selectedNonProfit: state.userReducer.selectedNonProfit,
    }
}

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            nonProfits: [],
            selectedNonProfit: '',
        }
    }


    componentDidMount = () => {
        // this.setState({loading: true})
        // const adminNonprofitsResponse = await getNonProfits(this.props.user);
        // const adminNonprofits = Object.values(adminNonprofitsResponse.admin_non_profits).filter(nonprofit => nonprofit !== "all");
        // const selectedNonProfit = adminNonprofits[0];
        // this.setState({nonProfits: adminNonprofits, selectedNonProfit: selectedNonProfit});
        // const nonProfitData = await getNonProfit(this.props.user,selectedNonProfit);
        // this.setState({nonProfitData: nonProfitData.non_profit, loading: false})
        this.pullNpInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedNonProfit !== prevProps.selectedNonProfit) {
            this.pullNpInfo();
        }
    }

    pullNpInfo = async () => {
        this.setState({loading: true})
        const nonProfitData = await getNonProfit(this.props.user,this.props.selectedNonProfit);
        this.setState({nonProfitData: nonProfitData.non_profit, loading: false})
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="h-[100vh] flex flex-col items-center justify-center">
                    <Spinner size={40}/>
                    <p className='text-sm mt-5 text-slate-500'>
                        Loading profile
                    </p>
                </div>
            )
        }
        return (
            <div>
                <div className='page-container relative pt-20 px-4 bg-slate-100 flex flex-col items-center gap-5 pb-5'>
                    <EditAccountWidget nonProfit={this.state.nonProfitData} pullNpInfo={this.pullNpInfo} user={this.props.user}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(EditProfile);
