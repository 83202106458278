export const STATES = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA",
    "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
    "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
    "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
    "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];

export const qualifiedOptions = [
    {value: "Director", label: "I'm a director"},
    {value: "Board", label: "I'm on the board"},
    {value: "Employee", label: "I'm an employee"},
    {value: "Volunteer", label: "I volunteer at this cause"},
    // {value: "other", label: "I have another role"},
];


export const NPOS = [
    {
        "id": "13-5562162",
        "ein": "13-5562162",
        "name": "Helen Keller International, Inc.",
        "mission": "Helen Keller International is a global health organization dedicated to eliminating preventable vision loss, malnutrition, and diseases of poverty.Co-founded by Helen Keller—and guided by her fierce optimism and belief in human potential—the organization delivers life changing health solutions to vulnerable families in places where the need is great but access to care is limited.In the U.S., Africa, and Asia, Helen Keller’s proven, science-based programs empower people to create opportunities in their own lives and build lasting change.",
        "ruling_year": 1963,
        "num_employees": "135",
        "website_url": "https://helenkellerintl.org/",
        "contact_email": "giving@hki.org",
        "contact_name": "Ms. Catherine Ewald",
        "contact_title": "Director, Fundraising Systems & Analytics",
        "address1": "1 Dag Hammarskjold Plz Fl 2",
        "city": "New York City",
        "state": "NY",
        "zip": "10017",
        "financials": {
            "total_revenue": 121068878,
            "total_assets": 112029852,
            "fiscal_year": 2022,
            "form_type": "990",
            "total_expenses": 92115451
        },
        "category": "Management & Technical Assistance",
        "logo_url": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2Fhelen.png?alt=media&token=842e80c9-ea0f-417a-a2bc-909700ea1d03",
        "cover_photo": "",
        "interested": [
            "jack",
            "stevemott",
            "johncervenka",
            "jackstra",
            "kind47",
            "morgan98"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "13-1624100",
        "ein": "13-1624100",
        "name": "Museum of Modern Art",
        "mission": "Founded in 1929 as an educational institution, The Museum of Modern Art is dedicated to being the foremost museum of modern art in the world. Through the leadership of its Trustees and staff, The Museum of Modern Art manifests this commitment by establishing, preserving, and documenting a permanent collection of the highest order that reflects the vitality, complexity and unfolding patterns of modern and contemporary art; by presenting exhibitions and educational programs of unparalleled significance; by sustaining a library, archives, and conservation laboratory that are recognized as international centers of research; and by supporting scholarship and publications of preeminent intellectual merit.",
        "ruling_year": 1952,
        "num_employees": "1365",
        "website_url": "www.moma.org",
        "contact_email": "lisa_mantone@moma.org",
        "contact_name": "Ms. Lisa Mantone",
        "contact_title": "Director of Development",
        "address1": "11 W 53rd St",
        "city": "New York City",
        "state": "NY",
        "zip": "10019",
        "financials": {
            "total_revenue": 262144582,
            "total_assets": 2331703503,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 270711724
        },
        "category": "Art Museums",
        "logo_url": "",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "careymott",
            "robyncervenka",
            "jackstra"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "06-0726487",
        "ein": "06-0726487",
        "name": "Save the Children Federation, Inc.",
        "mission": "Save the Children believes every child deserves a future. In the United States and around the world, we work every day to give children a healthy start in life, the opportunity to learn and protection from harm. When crisis strikes, and children are most vulnerable, we are always among the first to respond and the last to leave. We ensure children’s unique needs are met and their voices are heard. We deliver lasting results for millions of children, including those hardest to reach. We do whatever it takes for children – every day and in times of crisis – transforming their lives and the future we share.",
        "ruling_year": 1964,
        "num_employees": "1469",
        "website_url": "www.savethechildren.org",
        "contact_email": "erossetti@savechildren.org",
        "contact_name": "Ettore Rossetti",
        "contact_title": "",
        "address1": "501 Kings Hwy E Ste 400",
        "city": "Fairfield",
        "state": "CT",
        "zip": "06825",
        "financials": {
            "total_revenue": 808658178,
            "total_assets": 418433982,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 765901311
        },
        "category": "International Development, Relief Services",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=7911901&approved=True",
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/placeholders%2Fnon_profit_covers%2Fabstract%2F1.jpg?alt=media",
        "interested": [
            "jack",
            "stevemott",
            "starfishsocial",
            "christinerenee",
            "johncervenka",
            "jthannan",
            "ericminer",
            "stal",
            "jackson",
            "octavianaugustuscaesar",
            "gringoviking.d.b@gmail.com",
            "mvanzetta",
            "gmerch",
            "sdavis118",
            "starsass",
            "lalomanu2013",
            "daniel",
            "sydneeblack",
            "dersainte",
            "rendor",
            "ania",
            "donsherry",
            "samtruck73",
            "kind47",
            "kelleycervenka",
            "enrick",
            "rico",
            "jziegel88",
            "gomezelizabeth57yahoocom",
            "kevin",
            "dk",
            "moiseekyela",
            "steve",
            "rosetroyer016",
            "alexwf"
        ],
        "admins": {
            "jack": {
                "name": "Jack Phifer",
                "role": "Employee"
            }
        },
        "queried": true
    },
    {
        "id": "53-0196517",
        "ein": "53-0196517",
        "name": "Goodwill Industries International, Inc.",
        "mission": "Goodwill Industries International works to enhance the dignity and quality of life of individuals and families by strengthening communities, eliminating barriers to opportunity, and helping people in need reach their full potential through learning and the power of work.Find your local Goodwill at http://www.goodwill.org/locator/",
        "ruling_year": 1952,
        "num_employees": "133",
        "website_url": "www.goodwill.org",
        "contact_email": "lauren.lawson@goodwill.org",
        "contact_name": "Ms. Lauren Lawson-Zilai",
        "contact_title": "Senior Director, Public Relations",
        "address1": "15810 Indianola Dr",
        "city": "Derwood",
        "state": "MD",
        "zip": "20855",
        "financials": {
            "total_revenue": 77145526,
            "total_assets": 56845919,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 52306762
        },
        "category": "Human Service Organizations",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=6954163&approved=True",
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F53-0196517?alt=media&token=509562fb-23b6-494c-88b4-051ea42bf2ae",
        "interested": [
            "jack",
            "stevemott",
            "mvanzetta",
            "starfishsocial",
            "christinerenee",
            "nikokaplan",
            "aaronpowers",
            "timmywong",
            "loryncervenka",
            "oliver",
            "octavianaugustuscaesar",
            "jay",
            "scottstar",
            "jonnyb2",
            "gmerch",
            "jm06",
            "ajcartwright",
            "sydneeblack",
            "dersainte",
            "ania",
            "donsherry",
            "enrick",
            "rico",
            "goglue08@hotmail.com",
            "delgado101",
            "eva",
            "jackstra"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "36-3673599",
        "ein": "36-3673599",
        "name": "Feeding America",
        "mission": "Our mission is to advance change in America by ensuring equitable access to nutritious food for all in partnership with food banks, policymakers, supporters, and the communities we serve.",
        "ruling_year": 1990,
        "num_employees": "313",
        "website_url": "www.feedingamerica.org",
        "contact_email": "adepaul@feedingamerica.org",
        "contact_name": "Angela DePaul",
        "contact_title": "",
        "address1": "161 N Clark St Ste 700",
        "city": "Chicago",
        "state": "IL",
        "zip": "60601",
        "financials": {
            "total_revenue": 3571430346,
            "total_assets": 432594156,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 3309075710
        },
        "category": "Food Service, Free Food Distribution Programs",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=7879212&approved=True",
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F36-3673599?alt=media&token=e2208872-e17f-4f37-94e1-e98f4c8b6026",
        "interested": [
            "johncervenka",
            "jack",
            "dwilliams",
            "stevemott",
            "octavianaugustuscaesar",
            "emiliomartinez",
            "gringoviking.d.b@gmail.com",
            "garmsusd",
            "gtui",
            "chrishoffy",
            "wpaul01",
            "nike1202",
            "perrycherryann",
            "ghouge",
            "sdavis118",
            "ajcartwright",
            "careymott",
            "lalomanu2013",
            "gavin",
            "dersainte",
            "rendor",
            "jamal_hansen",
            "ania",
            "donsherry",
            "kelleycervenka",
            "enrick",
            "rico",
            "abbypodcast",
            "jziegel88",
            "elsiebyler",
            "georgeniekoley",
            "dk",
            "crowleyfyke",
            "moiseekyela",
            "jeffo",
            "levis",
            "al",
            "testertesting463728",
            "hcrawford2007@yshoo.com"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "92-0152268",
        "ein": "92-0152268",
        "name": "Tragedy Assistance Program For Survivors",
        "mission": "The Tragedy Assistance Program for Survivors (TAPS) is the national organization providing compassionate care for all those grieving the death of a military loved one. TAPS honors our military men and women by caring for all those they loved and left behind.",
        "ruling_year": 1995,
        "num_employees": "111",
        "website_url": "http://www.taps.org",
        "contact_email": "katie.maness@taps.org",
        "contact_name": "Katie Maness/TAPS",
        "contact_title": "",
        "address1": "3033 Wilson Blvd Ste 300",
        "city": "Arlington",
        "state": "VA",
        "zip": "22201",
        "financials": {
            "total_revenue": 10149599,
            "total_assets": 6482761,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 12303997
        },
        "category": "Family Services",
        "logo_url": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2FTAPS_Logo_larger_size-1.jpg?alt=media&token=a84ec346-1b15-4118-8fed-8d8d9db1bc23",
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F92-0152268.jpeg?alt=media",
        "interested": [
            "jsemcken",
            "stevemott",
            "johncervenka",
            "cmaxdudegmailcom",
            "ktmaness"
        ],
        "admins": {
            "ktmaness": {
                "name": "Katie Maness",
                "role": "Employee"
            }
        },
        "queried": true
    },
    {
        "id": "23-1907729",
        "ein": "23-1907729",
        "name": "JDRF International",
        "mission": "JDRF’s mission is to improve lives by accelerating life-changing breakthroughs to cure, prevent and treat T1D and its complications. To accomplish this, JDRF has invested more than $2.5 billion in research funding since our inception. We are an organization built on a grassroots model of people connecting in their local communities, collaborating regionally for efficiency and broader fundraising impact, and uniting on a national stage to pool resources, passion, and energy. We collaborate with academic institutions, policymakers, and corporate and industry partners to develop and deliver a pipeline of innovative therapies to people living with T1D. For more information, please visit jdrf.org or follow us on Twitter (@JDRF), Facebook (@myjdrf), and Instagram (@jdrfhq).",
        "ruling_year": 1973,
        "num_employees": "748",
        "website_url": "https://www.jdrf.org/",
        "contact_email": "rking@jdrf.org",
        "contact_name": "Rob King",
        "contact_title": "CFO",
        "address1": "200 Vesey St Fl 28",
        "city": "New York City",
        "state": "NY",
        "zip": "10281",
        "financials": {
            "total_revenue": 223022643,
            "total_assets": 361315226,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 115470447
        },
        "category": "Specifically Named Diseases",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=8420345&approved=True",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "johncervenka"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "13-4129457",
        "ein": "13-4129457",
        "name": "DonorsChoose",
        "mission": "DonorsChoose makes it easy for anyone to help a classroom in need, moving us closer to a nation where students in every community have the tools and experiences they need for a great education.",
        "ruling_year": 2000,
        "num_employees": "187",
        "website_url": "www.donorschoose.org",
        "contact_email": "christopher@donorschoose.org",
        "contact_name": "Christopher Pearsall",
        "contact_title": "Vice President, Brand and Communications",
        "address1": "134 W 37th St Fl 11",
        "city": "New York City",
        "state": "NY",
        "zip": "10018",
        "financials": {
            "total_revenue": 158050126,
            "total_assets": 123689070,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 139500343
        },
        "category": "Education N.E.C.",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=8637554&approved=True",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "careymott"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "13-6162659",
        "ein": "13-6162659",
        "name": "American Museum of Natural History",
        "mission": "The mission of the American Museum of Natural History is to discover, interpret, and disseminate - through scientific research and education - knowledge about human cultures, the natural world, and the universe.",
        "ruling_year": 1943,
        "num_employees": "1547",
        "website_url": "www.amnh.org",
        "contact_email": "jpowers@amnh.org",
        "contact_name": "Ms. Jackie Powers",
        "contact_title": "Special Assistant to the President and Assistant Secretary",
        "address1": "200 Central Park W",
        "city": "New York City",
        "state": "NY",
        "zip": "10024",
        "financials": {
            "total_revenue": 198055556,
            "total_assets": 1531677996,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 210518219
        },
        "category": "Natural History, Natural Science Museums",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=8864606&approved=True",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "careymott"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "13-3720473",
        "ein": "13-3720473",
        "name": "Do Something, Inc.",
        "mission": "DoSomething, Inc was founded in 1993 to advocate and promote social leadership, community activism and citizenship.  Also known as DoSomething.org, we are one of the largest global organizations for young people and social change.Do Something.org inspires, empowers and celebrates a generation of doers.  The services are directed to young people (ages 13-25) who want to make a difference, but need help actively participating and leading in community change efforts. DoSomething.org makes the world suck less. Our 5.5 million members tackle campaigns that impact every cause, from poverty to violence to the environment to literally everything else. Any cause, anytime, anywhere. The call to action is always something that has a real impact and doesn't require money, an adult or a car.",
        "ruling_year": 1995,
        "num_employees": "85",
        "website_url": "www.dosomething.org",
        "contact_email": "dgetachew@dosomething.org",
        "contact_name": "Mrs. DeNora Getachew",
        "contact_title": "CEO",
        "address1": "1202 Lexington Ave Ste 305",
        "city": "New York City",
        "state": "NY",
        "zip": "10028",
        "financials": {
            "total_revenue": 8284413,
            "total_assets": 17753347,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 11423421
        },
        "category": "Civil Rights, Social Action, and Advocacy N.E.C.",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=8407300&approved=True",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "careymott"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "13-1760110",
        "ein": "13-1760110",
        "name": "UNICEF USA",
        "mission": "Since its founding in 1947, the United Nations Children’s Fund (UNICEF) has built an unprecedented global support system for the world’s children. Through its many programs and partnerships in health care and immunization, safe water and sanitation, nutrition, education, emergency relief and other critical areas, UNICEF delivers the essentials needed to give children an equitable chance in life. UNICEF USA shares UNICEF’s vision of a world that upholds the rights of all children and helps every child thrive, and works to advance UNICEF’s mission through fundraising, advocacy and education in the United States. Together, we have helped meaningfully improve more children’s lives than any other humanitarian organization.",
        "ruling_year": 1949,
        "num_employees": "379",
        "website_url": "www.unicefusa.org",
        "contact_email": "aerikson@unicefusa.org",
        "contact_name": "Amelia Erikson",
        "contact_title": "Assistant Director, Marketing",
        "address1": "125 Maiden Ln",
        "city": "New York City",
        "state": "NY",
        "zip": "10038",
        "financials": {
            "total_revenue": 573865026,
            "total_assets": 394704546,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 588576039
        },
        "category": "International Relief",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=7888031&approved=True",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "careymott"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "53-0193519",
        "ein": "53-0193519",
        "name": "National Geographic Society",
        "mission": "The National Geographic Society uses the power of science, exploration, education and storytelling to illuminate and protect the wonder of our world.",
        "ruling_year": 1938,
        "num_employees": "481",
        "website_url": "http://www.nationalgeographic.org",
        "contact_email": "miking@ngs.org",
        "contact_name": "Mikaela King",
        "contact_title": "Vice President, Advancement Operations",
        "address1": "1145 17th St NW",
        "city": "Washington",
        "state": "DC",
        "zip": "20036",
        "financials": {
            "total_revenue": 70469785,
            "total_assets": 1696779808,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 145411426
        },
        "category": "Fund Raising and/or Fund Distribution",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=7809116&approved=True",
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F53-0193519?alt=media&token=3d08650c-e28a-47e2-9c4e-3824c05db64b",
        "interested": [
            "stevemott",
            "careymott",
            "jack"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "13-1656653",
        "ein": "13-1656653",
        "name": "Fresh Air Fund",
        "mission": "Since its founding in 1877, The Fresh Air Fund has provided life-changing summer experiences in the outdoors for children from New York City’s underserved communities. Despite the challenges presented by COVID-19, The Fund continues to provide safe, fun, engaging and enriching experiences for New York City youth to keep the magic of summer alive through virtual and outdoor programs. Fresh Air children also participate in year-round leadership and educational programs.",
        "ruling_year": 1933,
        "num_employees": "743",
        "website_url": "http://www.freshair.org",
        "contact_email": "aconteh@freshair.org",
        "contact_name": "Mr. Alpha Conteh",
        "contact_title": "Chief Financial Officer",
        "address1": "633 3rd Ave Fl 14",
        "city": "New York City",
        "state": "NY",
        "zip": "10017",
        "financials": {
            "total_revenue": 12966429,
            "total_assets": 135410982,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 18960367
        },
        "category": "Recreational and Sporting Camps (Day, Overnight, etc.)",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=7262821&approved=True",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "starfishsocial"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "13-1624016",
        "ein": "13-1624016",
        "name": "GIRL SCOUTS OF THE UNITED STATES OF AMERICA",
        "mission": "WITH THE SUPPORT OF CARING ADULT VOLUNTEERS AND MENTORS, GIRL SCOUTS     EXPLORE STEM (SCIENCE, TECHNOLOGY, ENGINEERING, AND MATH), THE     OUTDOORS, AND ENTREPRENEURSHIP, ALL WHILE DEVELOPING CRUCIAL LIFE     SKILLS THAT SERVE THEM WELL BEYOND THEIR TIME AS GIRL MEMBERS. THE GIRL     SCOUT PROGRAM IS PROVEN TO HELP GIRLS THRIVE IN FIVE KEY WAYS     DEVELOPING A STRONG SENSE OF SELF; SEEKING CHALLENGES AND LEARNING FROM     SETBACKS; DISPLAYING POSITIVE VALUES; FORMING AND MAINTAINING HEALTHY     RELATIONSHIPS; AND IDENTIFYING AND SOLVING PROBLEMS.",
        "ruling_year": 1919,
        "num_employees": "558",
        "website_url": "www.girlscouts.org",
        "contact_email": "OfficeoftheCFO@girlscouts.org",
        "contact_name": "Ms. Liana Gudin",
        "contact_title": "Finance Coordinator",
        "address1": "420 5th Ave",
        "city": "New York City",
        "state": "NY",
        "zip": "10018",
        "financials": {
            "total_revenue": 112222860,
            "total_assets": 258877900,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 122770123
        },
        "category": "Girl Scouts",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=8059214&approved=True",
        "cover_photo": "",
        "interested": [
            "gcervenka",
            "johncervenka",
            "jack"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "95-1643344",
        "ein": "95-1643344",
        "name": "PASADENA HUMANE SOCIETY AND S.P.C.A.",
        "mission": "Compassion and care for all animals.",
        "ruling_year": 1939,
        "num_employees": "185",
        "website_url": "www.pasadenahumane.org",
        "contact_email": "klamas@pasadenahumane.org",
        "contact_name": "Kristina Lamas",
        "contact_title": "",
        "address1": "361 S Raymond Ave",
        "city": "Pasadena",
        "state": "CA",
        "zip": "91105",
        "financials": {
            "total_revenue": 12182038,
            "total_assets": 42305923,
            "fiscal_year": 2019,
            "form_type": "990",
            "total_expenses": 13089540
        },
        "category": "Animal Protection and Welfare (includes Humane Societies and SPCAs)",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=8089158&approved=True",
        "cover_photo": "",
        "interested": [
            "annabellefung",
            "clairejolie",
            "dwilliams",
            "e",
            "everettpam",
            "garenishandsome",
            "jacobmayani",
            "jonnyb2",
            "lilahcervenka",
            "nataliesuh",
            "natasha",
            "stevemott",
            "jsiegrist",
            "wbmarble",
            "franklyn.cervenka39@berkeley.edu",
            "gcervenka",
            "robyncervenka",
            "tommyhannan",
            "jack",
            "loryncervenka",
            "mmyers8963",
            "jeffo",
            "meredith",
            "lisahenzel",
            "rusty"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "99-0315110",
        "ein": "99-0315110",
        "name": "Maui Food Bank, Inc.",
        "mission": "Maui Food Bank's mission is to help the hungry in Maui County by collecting and distributing food through community partnerships.",
        "ruling_year": 1995,
        "num_employees": "14",
        "website_url": "www.mauifoodbank.org",
        "contact_email": "rich@mauifoodbank.org",
        "contact_name": "Mr. Richard Yust",
        "contact_title": "Executive Director",
        "address1": "760 Kolu St",
        "city": "Wailuku",
        "state": "HI",
        "zip": "96793",
        "financials": {
            "total_revenue": 9472622,
            "total_assets": 6738660,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 8361656
        },
        "category": "Food Banks, Food Pantries",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=6387733&approved=True",
        "cover_photo": "",
        "interested": [
            "dwilliams",
            "jacobmayani",
            "jeffrussell",
            "armandinejacotingmailcom",
            "johncervenka",
            "loryncervenka",
            "stevemott",
            "denisecervenka",
            "chrisfdunbar",
            "careymott",
            "viktor"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "95-4766473",
        "ein": "95-4766473",
        "name": "Treasure Little Children Foundation Inc",
        "mission": "Treasure Little Children (TLC) is a group of dynamic volunteers that provide a holiday celebration with fun activities, music, games, and gifts to children of underserved communities.",
        "ruling_year": 2001,
        "num_employees": "",
        "website_url": "http://treasurelittlechildren.org",
        "contact_email": "",
        "contact_name": "",
        "contact_title": "",
        "address1": "330 Warren Way",
        "city": "Arcadia",
        "state": "CA",
        "zip": "91007",
        "financials": {
            "total_revenue": null,
            "total_assets": null,
            "fiscal_year": null,
            "form_type": "",
            "total_expenses": null
        },
        "category": "Food Service, Free Food Distribution Programs",
        "logo_url": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2F95-4766473.jpeg?alt=media",
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F95-4766473.jpeg?alt=media",
        "interested": [
            "hillary",
            "karinams",
            "lutherwaters",
            "porsmoi123",
            "jay",
            "morgan98",
            "jackstra",
            "lisahenzel",
            "jack",
            "jsemcken",
            "stevemott"
        ],
        "admins": {
            "johncervenka": {
                "name": "John Cervenka",
                "role": "Board"
            }
        },
        "queried": true
    },
    {
        "id": "27-4209721",
        "ein": "27-4209721",
        "name": "Special Forces Charitable Trust",
        "mission": "The Special Forces Charitable Trust (SFCT) is a 501(c)(3) charity that provides meaningful and sustainable support to the Green Berets, and their Families.  The SFCT supports timely, innovative, and comprehensive programs and services designed to enhance the well-being and health of both the Special Forces Soldier and his Family.",
        "ruling_year": 2011,
        "num_employees": "8",
        "website_url": "www.specialforcescharitabletrust.org",
        "contact_email": "jodi@specialforcescharitabletrust.org",
        "contact_name": "Mrs. Jodi Burns",
        "contact_title": "Executive Director",
        "address1": "PO Box 53",
        "city": "Southern Pines",
        "state": "NC",
        "zip": "28388",
        "financials": {
            "total_revenue": 3347524,
            "total_assets": 2361539,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 2408883
        },
        "category": "Public Foundations",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=9100399&approved=True",
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F27-4209721.jpeg?alt=media",
        "interested": [
            "jsemcken",
            "kcrawford",
            "stevemott",
            "johncervenka",
            "grace",
            "hberkowitz",
            "hbg18a",
            "sierracortez",
            "bmcfalls",
            "brekings15",
            "cmaxdudegmailcom",
            "jodiburns",
            "cameron_davis",
            "starfishsocial",
            "cynthiah"
        ],
        "admins": {
            "cameron_davis": {
                "name": "Cameron Davis",
                "role": "Director"
            }
        },
        "queried": true
    },
    {
        "id": "26-2932191",
        "ein": "26-2932191",
        "name": "Homes For Families",
        "mission": "Homes 4 Families empowers low-income veterans and their families to enter the middle class through affordable, full-equity homeownership and sustainable housing combined with holistic services that build resiliency, self-sufficiency and economic growth. Through our outcome-proven Enriched Neighborhood® model, we provide servicemen, servicewomen and their families with comprehensive wraparound services that equip them to succeed in civilian life, including financial education, therapeutic trauma-informed workshops, services for military children, and veteran-to-veteran support. With more than ten years of research-backed experience and success, Homes 4 Families works closely with the California Department of Veterans Affairs and others to build permanent, thriving veteran communities.",
        "ruling_year": 2014,
        "num_employees": "18",
        "website_url": "www.Homes4Families.org",
        "contact_email": "Donna@Deutchmandev.org",
        "contact_name": "Donna Deutchman",
        "contact_title": "President & CEO",
        "address1": "21031 Ventura Blvd Ste 610",
        "city": "Woodland Hills",
        "state": "CA",
        "zip": "91364",
        "financials": {
            "total_revenue": 6341825,
            "total_assets": 11771786,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 7207161
        },
        "category": "Housing Development, Construction, Management",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=8732289&approved=True",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "jackstra",
            "jack",
            "jfort24",
            "homes4families"
        ],
        "admins": {
            "homes4families": {
                "name": "Stacey  Chiang",
                "role": "Employee"
            }
        },
        "queried": true
    },
    {
        "id": "14-1260763",
        "ein": "14-1260763",
        "name": "Association of Graduates of the United State Military Academy",
        "mission": "The mission of the WPAOG is to serve West Point and its graduates.",
        "ruling_year": 1948,
        "num_employees": "164",
        "website_url": "http://www.westpointaog.org",
        "contact_email": "jamie.enos@wpaog.org",
        "contact_name": "Ms. Jamie Enos",
        "contact_title": "Director of Communications",
        "address1": "698 Mills Rd",
        "city": "West Point",
        "state": "NY",
        "zip": "10996",
        "financials": {
            "total_revenue": 129264808,
            "total_assets": 680065484,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 43421321
        },
        "category": "Alumni Associations",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=9227930&approved=True",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "jsemcken",
            "cmaxdudegmailcom",
            "cameron_davis"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "26-0710805",
        "ein": "26-0710805",
        "name": "Land of the Free Foundation",
        "mission": "THE CORPORATION IS COMMITTED TO CELEBRATING THE MEN AND WOMEN OF THE ARMED FORCES, PAST, PRESENT, AND FUTURE. THE PRIMARY PURPOSE OF THE CORPORATION IS TO SUPPORT PROGRAMS THAT HELP UNITES STATES ARMED SERVICES PERSONNEL AND THEIR FAMILIES.",
        "ruling_year": 2007,
        "num_employees": "0",
        "website_url": "HTTP://WWW.LANDOFTHEFREEFOUNDATION.COM/",
        "contact_email": "",
        "contact_name": "",
        "contact_title": "",
        "address1": "13191 Crossroads Pkwy N Fl 6",
        "city": "City of Industry",
        "state": "CA",
        "zip": "91746",
        "financials": {
            "total_revenue": 907894,
            "total_assets": 15071,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 945441
        },
        "category": "Public Foundations",
        "logo_url": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2F26-0710805?alt=media&token=65640094-0176-4374-8115-35b79d31e99c",
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F26-0710805?alt=media&token=9b4c60e4-5f48-42a1-baf4-4abbbde1dabc",
        "interested": [
            "johncervenka",
            "stevemott",
            "chrisfdunbar",
            "jack",
            "tommyhannan",
            "crowleyfyke",
            "naugsie",
            "danabowman",
            "csabol"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "22-1905062",
        "ein": "22-1905062",
        "name": "Marine Corps Scholarship Foundation Inc",
        "mission": "The Marine Corps Scholarship Foundation honors Marines by educating their children.  Founded in 1962, the Marine Corps Scholarship Foundation is the nation's oldest and largest provider of need-based scholarships to military children. For more than half a century, we've been providing access to affordable education for the children of Marine and Navy Corpsmen attending post-high school, undergraduate and career technical education programs.  In that time, we have provided more than 47,000 scholarships valued at nearly $145 million",
        "ruling_year": 1995,
        "num_employees": "40",
        "website_url": "www.mcsf.org",
        "contact_email": "steven.peterson@mcsf.org",
        "contact_name": "Steven Peterson",
        "contact_title": "Executive Vice President, Operations",
        "address1": "909 N Washington St Ste 400",
        "city": "Alexandria",
        "state": "VA",
        "zip": "22314",
        "financials": {
            "total_revenue": 37411876,
            "total_assets": 143461620,
            "fiscal_year": 2022,
            "form_type": "990",
            "total_expenses": 17245029
        },
        "category": "Scholarships, Student Financial Aid, Awards",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=6670967&approved=True",
        "cover_photo": "",
        "interested": [
            "jsemcken",
            "stevemott",
            "cameron_davis"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "23-7013563",
        "ein": "23-7013563",
        "name": "The Bridge Family Center, Inc.",
        "mission": "Formed in 1969 to help youth engaged in high-risk behaviors, the Bridge today is a comprehensive, regional nonprofit agency offering a broad range of services to children and families in Greater Hartford. We have 45 residential beds for young people; four mental health clinics that serve children, families, and adults; a Teen Center; a Mosaic Parenting Center for families working towards reunification, and a nationally recognized Family Resource Center. Last year, we served more than 8,500 children and families. Our mission is to foster the courage and strength in children and families to meet life's challenges and build fulfilling lives.",
        "ruling_year": 1969,
        "num_employees": "196",
        "website_url": "www.bridgefamilycenter.org",
        "contact_email": "dtarascio@bridgefamilycenter.org",
        "contact_name": "Ms. Doreen Tarascio",
        "contact_title": "Director of Development and Communications",
        "address1": "1022 Farmington Ave",
        "city": "West Hartford",
        "state": "CT",
        "zip": "06107",
        "financials": {
            "total_revenue": 8870553,
            "total_assets": 5589604,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 8010998
        },
        "category": "Children's and Youth Services",
        "logo_url": "https://www.guidestar.org/ViewEdoc.aspx?eDocId=7933745&approved=True",
        "cover_photo": "",
        "interested": [
            "johncervenka",
            "stevemott"
        ],
        "admins": {},
        "queried": true
    },
    {
        "id": "95-1643307",
        "ein": "95-1643307",
        "name": "California Institute of Technology",
        "mission": "SEE SCHEDULE O",
        "ruling_year": 1937,
        "num_employees": "12035",
        "website_url": "http://www.caltech.edu",
        "contact_email": "",
        "contact_name": "",
        "contact_title": "",
        "address1": "1200 E California Blvd # Mc2346",
        "city": "Pasadena",
        "state": "CA",
        "zip": "91125",
        "financials": {
            "total_revenue": 3522009000,
            "total_assets": 6252584000,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 3467103000
        },
        "category": "University or Technological",
        "logo_url": "",
        "cover_photo": "",
        "interested": [
            "stevemott",
            "careymott",
            "tommyhannan",
            "johncervenka"
        ],
        "admins": {},
        "queried": true
    }
]
