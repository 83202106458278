import React, {Component} from 'react';
import {VictoryPie} from 'victory';

class DonutChart extends Component {

    render() {
        return (
            <VictoryPie
                data={this.props.data}
                colorScale={['#1662F5', '#F8969C', '#5733FF', '#33A6FF']}
                innerRadius={80}
                style={{labels: {fill: "white", fontSize: 14, fontWeight: "bold"}}}
            />
        );
    }
}

export default DonutChart;
