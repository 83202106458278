import React, {PureComponent} from "react";
import ModalComponent from "../components/modals/ModalComponent";
import {sendPasswordResetEmail} from "firebase/auth";
import {firebaseAuth} from "../api/firebase";
import Spinner from "../components/Spinner";
import {Button} from "./atoms/buttons/Button";
import TextInput from "./atoms/inputs/TextInput";


class ForgotPassword extends PureComponent {
    state = {
        email: ''
    }

    onChangeEmail = (e) => {
        this.setState({email: e.target.value})
    }

    sendPasswordReset = () => {
        console.log("sending", this.state.email)
        sendPasswordResetEmail(firebaseAuth, this.state.email);
        this.setState({email: ""})
        this.props.closeModal();
    };

    render() {
        const buttonDisabled = !this.state.email;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={this.props.mobile ? 'full' : 'md'}
                toggle={this.props.closeModal}
                headerToggle
                backgroundColor={'white'}
            >
                <div className={`col-jc flex-1 p-8 pt-0`}>
                    <h1 className={`text-2xl font-semibold black-80 mb-0 mt-0 text-center`}>
                        Forgot your password?
                    </h1>
                    <div className='mt-5 row-ac body1' style={{marginTop: this.props.mobile && 24}}>
                        <div className='flex-1'>
                            <TextInput
                                label={'Email'}
                                name="email"
                                onChange={this.onChangeEmail}
                                value={this.state.email}
                                type="email"
                                placeholder="Your Email"
                            />
                        </div>
                    </div>
                    <Button
                        className='w-full mt-12'
                        disabled={buttonDisabled} onClick={this.sendPasswordReset}>
                        {this.state.processing ?
                            <Spinner/> : 'Submit'}
                    </Button>
                </div>
            </ModalComponent>
        )
    }
}

export default ForgotPassword
