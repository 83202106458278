import React, {Component} from 'react';
import '../styles/Navbar.css';
import {Link} from "react-router-dom";
import {withRouter} from "./navigation/withRouter";
import logo from '../img/brand/logo-word.png'
import SelectComponent from "./atoms/SelectComponent";
import {logout} from "../api/authentication";
import SvgPlusCircle from "../icons/SvgPlusCircle";
import {connect} from "react-redux";

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        setAuthUser: (user) => {
            dispatch({
                type: "SET_AUTH_USER",
                user,
            })
        },
        setMobile: (mobile) => {
            dispatch({
                type: "SET_MOBILE",
                mobile,
            })
        },
        setSelectedNonProfit: (nonProfit) => {
            dispatch({
                type: "SET_SELECTED_NON_PROFIT",
                nonProfit,
            })
        }
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userReducer.user,
        selectedNonProfit: state.userReducer.selectedNonProfit,
    }
}

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            prevScrollPos: window.pageYOffset,
            transparentNav: true,
            whiteNavbar: true,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    selectNonProfit = (selectedNonProfit) => {
        this.setState({selectedNonProfit})
    }

    handleScroll() {
        const currentScrollPos = window.pageYOffset;
        const visible = this.state.prevScrollPos > currentScrollPos || currentScrollPos <= 0;
        const isViewingHero = currentScrollPos <= 100
        this.setState({
            visible,
            prevScrollPos: currentScrollPos,
            transparentNav: isViewingHero
        });
    }

    render() {
        const {visible} = this.state;
        return (
            <nav
                className={`navbar ${visible ? 'visible' : 'hidden-nav'} bg-white border-b-slate-300 h-16 px-4 sm:px-8`}
                style={{
                    transition: '300ms ease-in-out',
                    // background: 'linear-gradient(to bottom, #2e3a5020 , #2e3a5000)'
                }}
            >
                <div className='flex flex-row items-center gap-12'>
                    <div className='flex flex-row items-center gap-1'>
                        <img className='w-32' alt={'logo'} src={logo}/>
                        <p className='text-[10px] font-bold text-blue-300 mb-4'>
                            ADMIN
                        </p>
                    </div>
                    <Link to={'/'}>
                        <p className='text-sm font-semibold text-neutral-400 hover:text-neutral-800 cursor-pointer'>
                            Analytics
                        </p>
                    </Link>
                    <Link to={'/edit'}>
                        <p className='text-sm font-semibold text-neutral-400 hover:text-neutral-800 cursor-pointer'>
                            Profile
                        </p>
                    </Link>
                    <Link to={'/funds'}>
                        <p className='text-sm font-semibold text-neutral-400 hover:text-neutral-800 cursor-pointer'>
                            Manage Funds
                        </p>
                    </Link>
                </div>
                <div className='flex flex-row items-center gap-5'>
                    <SelectComponent
                        value={this.props.selectedNonProfit}
                        setValue={this.props.setSelectedNonProfit}
                        options={this.props.nonProfits.map((nonProfit) => {
                            return {
                                title: nonProfit,
                                value: nonProfit
                            }
                        })}
                        label={'Select non-profit'}
                        light
                    />
                    {/*<button className=''>*/}
                    {/*    <div*/}
                    {/*        className="bg-white border border-[1px] border-gray-300 text-gray-900 text-sm rounded-lg block px-4 py-2">*/}
                    {/*        Tuesday, July 27th 2023*/}
                    {/*    </div>*/}
                    {/*</button>*/}
                    {/*<button className=''>*/}
                    {/*    <div*/}
                    {/*        className="bg-white border border-[1px] border-gray-300 text-gray-900 text-sm rounded-lg block px-4 py-2">*/}
                    {/*        Export*/}
                    {/*    </div>*/}
                    {/*</button>*/}
                    <Link to={'/claim'} className=''>
                        <div
                            className="border flex flex-row items-center border-[1px] border-blue-300 bg-blue-500 text-white text-sm rounded-lg block px-4 py-2">
                            <SvgPlusCircle className={'w-4 h-4 mr-2'}/> Add Nonprofit
                        </div>
                    </Link>
                    <button className='' onClick={logout}>
                        <div
                            className="bg-white border border-[1px] border-gray-300 text-gray-900 text-sm rounded-lg block px-4 py-2">
                            Logout
                        </div>
                    </button>
                </div>
            </nav>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
