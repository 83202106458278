import React, {Component} from 'react';
import CurrentBalanceWidget from "../components/widgets/CurrentBalanceWidget";
import PayoutHistoryWidget from "../components/widgets/PayoutHistoryWidget";
import {createConnectedAccount, deleteConnectedAccount, getConnectedAccount, getNonProfitBalance} from "../api/funding";
import {connect} from "react-redux";
import Spinner from "../components/Spinner";
import LinkABankWidget from "../components/widgets/LinkABankWidget";

function mapDispatchToProps(dispatch) {
    return {
        setAuthUser: (user) => {
            dispatch({
                type: "SET_AUTH_USER",
                user,
            })
        },
        setMobile: (mobile) => {
            dispatch({
                type: "SET_MOBILE",
                mobile,
            })
        },
        setSelectedNonProfit: (nonProfit) => {
            dispatch({
                type: "SET_SELECTED_NON_PROFIT",
                nonProfit,
            })
        }
    }
}

function mapStateToProps(state) {
    return {
        selectedNonProfit: state.userReducer.selectedNonProfit,
    }
}

class Funds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            nonProfits: [],
            nonProfitData:
                {
                    overview: {},
                    header: {},
                    line_chart: {},
                    bar_chart: {},
                    history: [],
                    age_ranges: [],
                    challenge_v_direct: [{'x': ''}, {'x': ''}],
                    followers_v_donors: [{'x': ''}, {'x': ''}],
                },
            selectedNonProfit: '',
            linkLoading: false,
            connectedAccount: {},
            bankAccount: {},
            connected: false,
            accountNumber: '',
            balance: {}
        }
    }


    componentDidMount = () => {
        this.getBankInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedNonProfit !== prevProps.selectedNonProfit) {
            this.getBankInfo();
        }
    }

    getBankInfo = async () => {
        this.setState({loading: true})
        const connectedAccount = await getConnectedAccount(this.props.user, this.props.selectedNonProfit);
        const bankAccount = connectedAccount?.external_accounts?.data[0];
        const balance = await getNonProfitBalance(this.props.user, this.props.selectedNonProfit);
        this.setState({
            loading: false,
            connectedAccount: connectedAccount,
            bankAccount: bankAccount,
            connected: !!bankAccount,
            balance: balance
        })
    }

    tryLinkAccount = async () => {
        this.setState({linkLoading: true})
        const newAccount = await createConnectedAccount(this.props.user, this.props.selectedNonProfit, this.state.accountNumber);
        this.setState({connectedAccount: newAccount, connected: true, linkLoading: false})
    }

    changeAccountNumber = (e) => {
        this.setState({accountNumber: e.target.value})
    }

    deleteAccount = async () => {
        this.setState({loading: true})
        const connectedAccount = await deleteConnectedAccount(this.props.user, this.props.selectedNonProfit);
        this.setState({
            loading: false,
            connectedAccount: {},
            bankAccount: {},
            connected: false
        })
    }


    render() {
        if (this.state.loading) {
            return (
                <div className="h-[100vh] flex flex-col items-center justify-center">
                    <Spinner size={40}/>
                    <p className='text-sm mt-5 text-slate-500'>
                        Loading funds
                    </p>
                </div>
            )
        }
        return (
            <div>
                <div className='page-container relative pt-20 px-4 bg-slate-100 flex flex-col gap-5 pb-5'>
                    {/*<FundsOverviewWidget data={this.state.nonProfitData.overview}/>*/}
                    <CurrentBalanceWidget bankAccount={this.state.bankAccount} connected={this.state.connected}
                                          tryLinkAccount={this.tryLinkAccount} deleteAccount={this.deleteAccount}
                                          changeAccountNumber={this.changeAccountNumber}
                                          balance={this.state.balance} loading={this.state.loading}
                    />
                    <div className='relative'>
                        {!this.state.connected &&
                            <div className='absolute left-0 right-0 top-0 z-[0] blur-sm'>
                                <PayoutHistoryWidget dummyData/>
                            </div>
                        }
                    </div>
                    {this.state.connected &&
                        <PayoutHistoryWidget/>
                    }
                    <LinkABankWidget linkLoading={this.state.linkLoading} accountNumber={this.state.accountNumber}
                                     bankAccount={this.state.bankAccount} connected={this.state.connected}
                                     tryLinkAccount={this.tryLinkAccount} deleteAccount={this.deleteAccount}
                                     changeAccountNumber={this.changeAccountNumber}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Funds);
