import axios from "./axios";

export const getNonProfits = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/admin/non_profits", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getDashboard = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/admin/dashboard", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getDashboardOverview = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/admin/dashboard/overview", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getDashboardHeader = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/admin/dashboard/header", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getDashboardCharts = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/admin/dashboard/charts", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getDashboardHistory = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/admin/dashboard/history", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getSocialActivity = async (user, ein = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/admin/dashboard/activity", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

