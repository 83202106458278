import React, {Component} from "react";
import SvgArrowLeft from "../../icons/SvgArrowLeft";
import {withRouter} from "../navigation/withRouter";

class GoBack extends Component {
    render() {
        return (
            <div
                onClick={() => this.props.history.goBack()}
                className='absolute top-6 left-6 flex flex-row items-center gap-3 text-slate-500 hover:text-slate-800 cursor-pointer'>
                <SvgArrowLeft className={'w-5 h-5'}/> Go back
            </div>
        )
    }
}

export default withRouter(GoBack)
