import React, {PureComponent} from "react";
import SvgArrowDropDown from "../../icons/SvgArrowDropDown";
import DropdownComponent from "./DropdownComponent";
import SvgClose from "../../icons/SvgClose";

class SelectComponent extends PureComponent {
    render() {
        return (
            <DropdownComponent
                ToggleComponent={
                    <div className={`bg-white border border-[1px] border-gray-300 text-gray-900 text-sm rounded-lg block px-4 py-2 flex flex-row items-center cursor-pointer ${this.props.toggleClassName}`}>
                        <div className='flex flex-row items-center flex-1'>
                                        <span className='mr-1'>
                                        {this.props.value || this.props.label}
                                    </span>
                            {this.props.value && this.props.clearFilter &&
                                <div className='text-white mr-2 mt-0.5' onClick={this.props.clearFilter}>
                                    <SvgClose className={'h-4 w-4'}/>
                                </div>
                            }
                        </div>
                        <SvgArrowDropDown className={'w-4 h-4'}/>
                    </div>
                }
                direction={this.props.position || 'bottom'}
                value={this.props.value}
                selectDropdown
                setValue={this.props.setValue}
                options={this.props.options}
                light={this.props.light}
                disabled={this.props.disabled}
                makeScrollable={this.props.makeScrollable}
            />
        )
    }
}

export default SelectComponent
