import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={33}
            height={33}
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.5 18.906a6.537 6.537 0 006.531-6.531A6.537 6.537 0 0016.5 5.844a6.537 6.537 0 00-6.531 6.531 6.537 6.537 0 006.531 6.531zm0-11a4.477 4.477 0 014.469 4.469 4.477 4.477 0 01-4.469 4.469 4.478 4.478 0 01-4.469-4.469A4.478 4.478 0 0116.5 7.906z"
                fill="#1662F5"
            />
            <path
                d="M24.227 11.069a3.124 3.124 0 011.554 2.68 3.098 3.098 0 01-3.093 3.095c-.564 0-1.032.467-1.032 1.03 0 .565.468 1.032 1.032 1.032a5.158 5.158 0 005.156-5.156c0-1.829-.99-3.548-2.572-4.469a1.046 1.046 0 00-1.416.371 1.026 1.026 0 00.371 1.403v.014zM10.313 18.906c.563 0 1.03-.467 1.03-1.031 0-.564-.467-1.031-1.03-1.031a3.098 3.098 0 01-3.094-3.094c0-1.1.591-2.131 1.554-2.681a1.02 1.02 0 00.37-1.403 1.03 1.03 0 00-1.416-.371 5.156 5.156 0 00-2.57 4.469 5.158 5.158 0 005.155 5.156v-.014zM16.5 20.969a6.323 6.323 0 00-6.133 4.785l-.027.123 1.004.248 1.03.124A4.238 4.238 0 0116.5 23.03a4.238 4.238 0 014.125 3.218c.11.467.536.784 1.004.784.082 0 .165 0 .247-.028.55-.137.894-.701.756-1.251a6.299 6.299 0 00-6.132-4.785zM10.656 22c0-.564-.468-1.031-1.031-1.031A4.544 4.544 0 005.21 24.42l-.358 1.457c-.137.55.193 1.114.757 1.252a.761.761 0 00.247.027c.468 0 .88-.316 1.004-.784l.357-1.457a2.475 2.475 0 012.42-1.884c.564 0 1.032-.467 1.032-1.031h-.014zM28.16 25.878l-.358-1.458a4.543 4.543 0 00-4.413-3.451c-.564 0-1.032.467-1.032 1.031 0 .564.468 1.031 1.032 1.031 1.14 0 2.145.77 2.42 1.884l.357 1.457c.11.468.536.784 1.004.784.082 0 .165 0 .247-.027.55-.138.894-.702.757-1.252h-.014z"
                fill="#1662F5"
            />
        </svg>
    )
}

export default SvgComponent
