import React, {Component} from 'react';
import RangeBar from "../../components/charts/atoms/RangeBar";
import DonutChart from "../charts/DonutChart";
import LineChart from "../charts/LineChart";

class LineChartWidget extends Component {

    render() {
        return (
            <div className='bg-white rounded-lg shadow-lg p-0 flex flex-col'>
                <p className='px-6 pt-6 text-gray-800 text-2xl font-bold'>
                    Donation Timeline
                </p>
                <LineChart data={this.props.data}/>
                <div className='flex flex-row gap-6 px-6 pb-6'>
                    <div className='flex flex-row items-center gap-2'>
                        <div style={{width: 12, height: 12, backgroundColor:'#1662F5'}} className='rounded-full'/>
                        <p className='text-lg font-bold text-gray-800'>
                            Total Donations
                        </p>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                        <div style={{width: 12, height: 12, backgroundColor:'#F8969C'}} className='rounded-full'/>
                        <p className='text-lg font-bold text-gray-800'>
                            Recurring Donations
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default LineChartWidget;
