import React, {Component} from 'react';
import {Button} from "../atoms/buttons/Button";
import TextInput from "../atoms/inputs/TextInput";
import SvgStripe from "../../icons/SvgStripe";
import banks from '../../img/banks.png'
import SvgConnect from "../../icons/SvgConnect";

class LinkABankWidget extends Component {


    render() {
        const buttonDisabled = this.props.accountNumber.length === 0 || this.props.linkLoading
        const bankAccount = this.props.bankAccount || {};
        return (
            !this.props.connected ?
                <div className='flex flex-col items-center mt4 relative z-[10]'>
                    <div
                        className='bg-white rounded-lg shadow-2xl border-slate-200 border flex flex-col gap-5 p-6 w-[600px]'>
                        <div className='flex flex-row items-center justify-center gap-5 mb-2'>
                            <img src={banks} className='w-[180px]'/>
                            <div>
                                <SvgConnect className={'text-slate-400'}/>
                            </div>
                           <div className='w-[180px]'>
                               <SvgStripe className={'text-indigo-500'}/>
                           </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <p className='text-xl font-semibold'>
                                Connect a bank account to withdraw funds
                            </p>
                            <p className='text-sm text-slate-700'>
                                Any funds donated directly through the Starfish platform (Web, iOS, Android) will be made
                                available to withdraw to the bank you connect. This can be changed at any time from your
                                admin account. Having issues? Contact <span className='text-blue-500'>info@starfishapp.com</span> for help.
                            </p>
                        </div>
                        <div className='w-full'>
                            <p className='text-gray-800 text-sm font-semibold'>
                                Enter Bank Account Number
                            </p>
                            <TextInput type={'number'} onChange={(e) => this.props.changeAccountNumber(e)}/>
                        </div>
                        <Button loading={this.props.linkLoading} disabled={buttonDisabled} className='w-full' onClick={this.props.tryLinkAccount}>
                            {this.props.linkLoading ? 'Processing...' : 'Get Started'}
                        </Button>
                        <div className='flex flex-col items-center gap-2'>
                            <div className='flex flex-row items-center justify-center'>
                                <p className='text-xs text-slate-500 mr-[-4px]'>
                                    Powered by
                                </p>
                                <SvgStripe className={'text-slate-400 hover:text-indigo-500'} width={80}/>
                            </div>
                            <p className='text-[10px] text-slate-500 text-center'>
                                Stripe's financial integration services employ industry-leading encryption standards,
                                including AES-256 encryption for data storage and TLS for data transmission, ensuring
                                that sensitive information is securely transmitted and stored. Stripe
                                rigorously adheres to PCI DSS compliance, implements two-factor authentication, and uses
                                continuous monitoring and machine learning algorithms to detect and prevent fraudulent
                                activities, providing a highly secure and reliable financial infrastructure.
                            </p>
                        </div>
                    </div>
                </div>
                :
                <></>
        );
    }
}

export default LinkABankWidget;
