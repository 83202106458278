import React, {Component} from "react";
import ProgressStepper from "../components/atoms/ProgressStepper";
import ClaimNPOPersonalProfile from "../components/ClaimNPOPersonalProfile";
import {Button} from "../components/atoms/buttons/Button";
import ClaimNPOSearch from "../components/ClaimNPOSearch";
import ClaimNPOBackground from "../components/ClaimNPOBackground";
import {connect} from "react-redux";
import ClaimNPOComplete from "../components/ClaimNPOComplete";
import {applyNonProfit} from "../api/nonProfits";
import {editUser} from "../api/users";
import { Link } from "react-router-dom";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser
    }
}

class ClaimNonprofitWizard extends Component {
    state = {
        step: 1,
        roles: "",
        message: "",
        selectedNonprofit: {},
        firstName: "",
        lastName:'',
        bio: "",
        city: "",
        state: "",
    }
    firstNameChanged = e => this.setState({firstName: e.target.value});
    lastNameChanged = e => this.setState({lastName: e.target.value});
    bioChanged = e => this.setState({bio: e.target.value});
    cityChanged = e => this.setState({city: e.target.value});
    stateChanged = e => this.setState({state: e});

    updateUser = async () => {
        const params = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            bio: this.state.bio,
            city: this.state.city,
            state: this.state.state,
            onboarded: true,
        }
        await editUser(this.props.authUser, params);
    }

    setSelectedNp = (selectedNonprofit) => {
        this.setState({selectedNonprofit});
        this.nextStep();
    }

    nextStep = () => {
        if (this.state.step === 1) {
            this.updateUser();
        } else if (this.state.step === 3) {
            this.apply();
        }
        this.setState({step: this.state.step + 1})
    }

    prevStep = () => {
        this.setState({step: this.state.step - 1})
    }

    apply = async () => {
        await applyNonProfit(this.props.authUser, this.state.selectedNonprofit.ein, this.state.roles, this.state.message);
    }

    handleRoleChange = (role) => {
        this.setState({roles: role})
        // this.setState(prevState => {
        //     const roles = [...prevState.roles];
        //     const index = roles.indexOf(role);
        //     console.log(roles, index)
        //     if (index > -1) {
        //         roles.splice(index, 1);
        //     } else {
        //         roles.push(role);
        //     }
        //     return {roles};
        // });
    };

    handleMessageChange = (e) => {
        this.setState({message: e.target.value})
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.user !== this.props.user) {
            this.setInfo();
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    setInfo = () => {
        const user = this.props.user || {};
        this.setState({firstName: user.first_name, lastName: user.last_name});

        if (this.props.user?.onboarded) {
            this.setState({step: 2});
        }
    }

    render() {
        const panelWidth = 'sm:w-[95vw] md:w-[80vw] lg:w-[60vw]'
        const stepLabels = ['Create personal account', 'Find your nonprofit', 'Give your background'];
        return (
            <div className='page-container relative pt-5 px-8 bg-slate-100 flex flex-col gap-5 pb-5'>
                <div className='burst'/>
                <div className='burst2'/>
                <div className='mb-5 flex flex-col items-center'>
                    <img className='w-40' src={require('../img/brand/full_logo.png')}/>
                </div>
                <div className='flex flex-col items-center'>
                    <div className={`${panelWidth}`}>
                        <ProgressStepper
                            step={this.state.step}
                            stepLabels={stepLabels}
                        />
                    </div>
                    <div className='flex flex-col items-center'>
                        <div
                            className={`bg-white rounded-2xl shadow-lg ${panelWidth} p-0 flex flex-col p-6 relative`}>
                            {this.state.step === 1 &&
                                <ClaimNPOPersonalProfile
                                    firstName={this.state.firstName}
                                    firstNameChanged={this.firstNameChanged}
                                    lastName={this.state.lastName}
                                    lastNameChanged={this.lastNameChanged}
                                    bio={this.state.bio}
                                    bioChanged={this.bioChanged}
                                    city={this.state.city}
                                    cityChanged={this.cityChanged}
                                    state={this.state.state}
                                    stateChanged={this.stateChanged}
                                />
                            }
                            {this.state.step === 2 &&
                                <ClaimNPOSearch setSelectedNp={this.setSelectedNp}/>
                            }
                            {this.state.step === 3 &&
                                <ClaimNPOBackground
                                    roles={this.state.roles}
                                    handleRoleChange={this.handleRoleChange}
                                    selectedNonprofit={this.state.selectedNonprofit}
                                    message={this.state.message}
                                    handleMessageChange={this.handleMessageChange}
                                />
                            }
                            {this.state.step === 4 &&
                                <ClaimNPOComplete/>
                            }
                        </div>
                    </div>
                    {this.state.step !== 4 ?
                        <div className='flex flex-row justify-center z-10 items-center gap-3 mt-8'>
                            {(this.state.step !== 1 && !(this.state.step === 2 && this.props.user?.onboarded)) ?
                                <Button variant={'secondary'} onClick={this.prevStep}>
                                    {this.state.step === 1 ? "Cancel" : "Back"}
                                </Button>
                                : null
                            }
                            {this.state.step !== 2 ?
                                <Button onClick={this.nextStep} disabled={this.state.step === 3 && (!this.state.roles || !this.state.message)}>
                                    {this.state.step !== 3 ? "Next" : "Submit"}
                                </Button>
                            : null}
                        </div>
                        :
                        <div className='flex flex-row justify-center items-center gap-3 mt-8'>
                            <Link to={'/'}>
                                <Button>
                                    Done
                                </Button>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ClaimNonprofitWizard)
