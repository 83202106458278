import React, {PureComponent} from 'react';
import {Navigate, Redirect, Route, Switch, Routes} from "react-router-dom";
import PropTypes from "prop-types";
import AuthPage from "./pages/AuthPage";
import Spinner from "./components/Spinner";
import SvgLogo from "./icons/SvgLogo";
import Home from "./pages/Home";
import {getNonProfits} from "./api/dashboard";
import {Button} from "./components/atoms/buttons/Button";
import Funds from "./pages/Funds";
import {connect} from "react-redux";
import EditProfile from "./pages/EditProfile";
import DefaultPage from './pages/DefaultPage';
import ClaimNonprofitWizard from "./pages/ClaimNonprofitWizard";
import {getUser} from './api/users';
import Navbar from "./components/Navbar";
import WithNav from "./components/navigation/WithNav";
import WithoutNav from "./components/navigation/WithoutNav";

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        setAuthUser: (user) => {
            dispatch({
                type: "SET_AUTH_USER",
                user,
            })
        },
        setMobile: (mobile) => {
            dispatch({
                type: "SET_MOBILE",
                mobile,
            })
        },
        setSelectedNonProfit: (nonProfit) => {
            dispatch({
                type: "SET_SELECTED_NON_PROFIT",
                nonProfit,
            })
        }
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userReducer.user,
    }
}

class Router extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            initializing: true,
            nonProfits: [],
            placeholder: false
        }
    }

    componentDidMount() {
        this.userUpdated()
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.userUpdated();
        }
    }

    resize() {
        this.setState({
            placeholder: window.innerWidth <= 760,
        });
    }


    userUpdated = async () => {
        if (this.props.user !== 0) {
            if (this.props.user) {
                this.setState({initializing: true})
                this.props.setAuthUser(this.props.user);
                const adminNonprofits = await getNonProfits(this.props.user);
                const nonProfits = adminNonprofits.admin_non_profits;
                const selectedNonProfit = nonProfits.length > 0 ? nonProfits[0] : null;
                this.props.setSelectedNonProfit(selectedNonProfit);
                this.setState({nonProfits: adminNonprofits.admin_non_profits, initializing: false});
                const res = await getUser(this.props.user);
                this.props.setUser(res.user);
            }
        } else {
            this.setState({initializing: false});
        }
    }

    render() {
        if (this.state.initializing) {
            return (
                <div className="h-[100vh] flex flex-col items-center justify-center">
                    <Spinner size={40}/>
                    <p className='text-sm mt-5 text-slate-500'>
                        Loading app
                    </p>
                </div>
            )
        }

        if (!this.props.user) {
            return (
                <Routes>
                    <Route exact path="/authentication" element={<AuthPage/>}/>
                    <Route path="*" element={<Navigate to="/authentication" replace/>}/>
                </Routes>
            )
        }
        if (this.state.nonProfits.length === 0) {
            return (
                <>
                    <Routes>
                        <Route path="/"
                               element={<DefaultPage/>}/>
                        <Route path="/claim"
                               element={<ClaimNonprofitWizard user={this.props.user}/>}/>
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                    </Routes>
                </>

            )
        }
        return (
            <>
                <Routes>
                     <Route element={<WithNav nonProfits={this.state.nonProfits}/>}>
                         <Route path="/" element={<Home user={this.props.user}
                                                         nonProfits={this.state.nonProfits}/>}/>
                         <Route path="/funds" element={<Funds user={this.props.user}
                                                         nonProfits={this.state.nonProfits}/>}/>
                         <Route path="/edit" element={<EditProfile user={this.props.user}/>}/>
                         <Route path="*" element={<Navigate to="/" replace/>}/>
                     </Route>
                    <Route element={<WithoutNav/>}>
                        <Route exact path="/claim" element={<ClaimNonprofitWizard user={this.props.user}/>}/>
                    </Route>

                </Routes>
            </>
        );
    }
}

Routes.propTypes = {
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
