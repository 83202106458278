import React, {Component} from 'react';
import HeaderStat from "../HeaderStat";
import SvgUsers from "../../icons/SvgUsers";

class PayoutListItem extends Component {

    render() {
        return (
            <div className='flex flex-row items-center gap-20 py-3 border-b border-b-neutral-200 last:border-none body2'>
                <p className={'flex-1'}>
                    August 12, 2023
                </p>
                <p className={'flex-1'}>
                    $1202903
                </p>
                <p className={'flex-1'}>
                    Bank Acct Name
                </p>
            </div>
        );
    }
}

export default PayoutListItem;
