import React, {Component} from 'react';
import TextInput from "./atoms/inputs/TextInput";
import {Button} from "./atoms/buttons/Button";
import SvgPlusCircle from "../icons/SvgPlusCircle";
import Dropzone from 'react-dropzone'
import GoBack from "./atoms/GoBack";
import SelectComponent from "./atoms/SelectComponent";
import {NPOS, qualifiedOptions, STATES} from "../utils/constants";
import SvgPin from "../icons/SvgPin";

class ClaimNPOBackground extends Component {

    render() {
        const np = this.props.selectedNonprofit;
        return (
            <div className='flex flex-col gap-3'>
                <p className='text-gray-800 text-2xl font-bold'>
                    Tell us why you should manage this account
                </p>
                <p className='text-md text-slate-500'>
                    Let us know your role at the cause you selected so we can ensure only qualified members get admin
                    access to your causes public page
                </p>
                <p className='text-md font-semibold text-slate-500 mt-5'>
                    Your selected cause:
                </p>
                <div className='p-4 flex flex-row br-12 bg-white border-2 border-transparent  rounded-lg'
                        key={np.ein}
                        style={{
                            boxShadow: '0 4px 12px #00000030',
                        }}
                        onError={(e) => {
                            e.target.src =
                                "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                            this.setState({imageError: true});
                        }}
                >
                    <img src={np.logo_url} style={{
                        width: 64,
                        height: 64,
                        objectFit: "contain",
                        backgroundColor: "white",
                        border: '2px solid #f6f6f6'
                    }}
                            className='rounded-lg bg-white-100'

                    />
                    <div className='col-plain ml-5' style={{textAlign: "left"}}>
                        <p className='body1-bold text-slate-800'>
                            {np.name}
                        </p>
                        <p className='body2 black-70 text-slate-500'>
                            {np.category}
                        </p>
                        <div className='flex flex-row items-center gap-1 mt-2 text-slate-500'>
                            <SvgPin className={'primary'} style={{width: 16, height: 16}}/>
                            <p className='body3-bold primary'>
                                {np.city}, {np.state}
                            </p>
                        </div>
                    </div>
                </div>
            
                <div className='flex flex-col flex-1 gap-3 mt-5'>
                    <p className='text-lg text-slate-800 font-semibold'>
                        Your role at this nonprofit
                    </p>
                    <fieldset className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
                        {qualifiedOptions.map((option) => (
                            <div key={option.value}>
                                <input
                                    type="radio"
                                    name="locationOption"
                                    value={option.value}
                                    id={option.value}
                                    className="hidden"
                                    checked={this.props.roles.includes(option.value)}
                                    onChange={() => this.props.handleRoleChange(option.value)}
                                />
                                <label
                                    htmlFor={option.value}
                                    className={`block cursor-pointer rounded-lg border-2 border-gray-100 bg-white p-3 text-sm font-medium shadow-sm hover:border-gray-200 ${
                                        this.props.roles.includes(option.value)
                                            ? "border-blue-500 ring-1 ring-blue-500"
                                            : ""
                                    }`}
                                >
                                    <div className="flex items-center justify-between">
                                        <p className='text-md text-slate-800 font-semibold'>
                                            {option.label}
                                        </p>
                                        <svg
                                            className={`${this.props.roles.includes(option.value) ? '' : 'hidden'} h-5 w-5 text-blue-600`}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                        ))}
                    </fieldset>
                    <TextInput
                        value={this.props.message}
                        onChange={this.props.handleMessageChange}
                        placeholder={'Explain your role in more detail...'}
                        type="default"
                        textarea
                    />
                </div>

            </div>
        );
    }
}

export default ClaimNPOBackground;
