
const Typesense = require('typesense')

let client = new Typesense.Client({
    'nodes': [{
        'host': 'tmijaf6pneb38ol7p-1.a1.typesense.net',
        'port': '443',
        'protocol': 'https'
    }],
    'apiKey': 'h3mIbG2zX2jG8w4x8iCAR6THt30l8E7n',
    'connectionTimeoutSeconds': 2
})

export const nonProfitIndex = client.collections('non_profits').documents();
export const userIndex = client.collections('users').documents();
export const hashtagIndex = client.collections('hashtags').documents();


export const filteredNonProfits = async (categories, city, state) => {
    const formattedCategories = categories.map(cat => `${cat}`)
    const filter = `category:=[${formattedCategories}] && state:=${state} && city:${city}`;
    const searchParams = {
        q: '',
        query_by: 'name',
        filter_by: filter,
        per_page: 8,
    }
    const response = await nonProfitIndex.search(searchParams);
    const nonProfitResults = response.hits.map((hit) => {
        hit.document.id = hit.document.ein;
        return hit.document;
    })
    return nonProfitResults;
}

export const searchNonProfits = async (query) => {
    const searchParams = {
        q: query,
        query_by: 'name,city,ein',
        per_page: 10,
    }
    const response = await nonProfitIndex.search(searchParams);
    const nonProfitResults = response.hits.map((hit) => {
        hit.document.id = hit.document.ein;
        return hit.document;
    })
    return nonProfitResults;
}


export const searchUsers = async (query) => {
    const searchParams = {
        q: query,
        query_by: 'first_name,last_name,username,city',
        per_page: 10,
    }
    const response = await userIndex.search(searchParams);
    const userResults = response.hits.map((hit) => {
        hit.document.id = hit.document.username;
        return hit.document;
    })
    return userResults;
}

export const searchHashtags = async (query) => {
    const searchParams = {
        q: query,
        query_by: 'hashtag',
        per_page: 10,
    }
    const response = await hashtagIndex.search(searchParams);
    const hashtagResults = response.hits.map((hit) => {
        hit.document.id = hit.document.hashtag
        return hit.document;
    })
    return hashtagResults;
}
