import React, {PureComponent} from "react";

class TextInput extends PureComponent {
    render() {
        if (this.props.textarea) {
            return (
                <div className='flex flex-col gap-1'>
                    <p className='body3-bold text-gray-400'>
                        {this.props.label}
                    </p>
                    <textarea
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        className='
                        min-h-16
                        border-[1px] text-3-400 rounded-lg py-1 px-2 outline-0 border-neutral-200 focus:border-blue-500 text-2-400 text-gray-900'/>
                </div>
            )
        }
        return (
            <div className={`flex flex-col gap-1 ${this.props.className}`}>
                <p className='body3-bold text-gray-400'>
                    {this.props.label}
                </p>
                <input
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    type={this.props.type}
                    className='border-[1px] text-3-400 rounded-lg py-1 px-2 outline-0 border-neutral-200 focus:border-blue-500 text-2-400 text-gray-900'/>
            </div>
        )
    }
}

export default TextInput
