import React, {Component} from 'react';

class RangeBar extends Component {

    render() {
        return (
            <div className='flex flex-row items-center'>
                <p className='w-16 text-md font-bold text-gray-500'>
                    {this.props.label}
                </p>
                <div className='bg-blue-100 rounded-sm flex-1 h-8 overflow-hidden'>
                    <div className='bg-blue-500 h-8' style={{width:this.props.pct + '%'}}/>
                </div>
                <p className='w-8 text-md font-bold text-gray-500 m-3'>
                    {this.props.pct.toFixed(2)}%
                </p>
            </div>

        );
    }
}

export default RangeBar;
