import React, {Component} from 'react';
import TextInput from "./atoms/inputs/TextInput";
import SvgPlusCircle from "../icons/SvgPlusCircle";
import Dropzone from 'react-dropzone'
import SelectComponent from "./atoms/SelectComponent";
import {STATES} from "../utils/constants";
import {uploadDoc} from "../api/firebase";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user
    }
}

class ClaimNPOPersonalProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {

            logoUrl: "",
            coverPhoto: "",
        };
    }


    onDrop = async (acceptedFiles, type) => {
        const url = await uploadDoc("users/" + type, this.props.user.username, acceptedFiles[0]);
        if (type === 'profile_picture') {
            this.setState({logoUrl: url});
        } else {
            this.setState({coverPhoto: url});
        }
    }

    render() {
        const hasProfilePic = !!this.state.logoUrl;
        const hasCoverPic = !!this.state.coverPhoto;
        return (
            <div className='flex flex-col gap-3'>
                <p className='text-gray-800 text-2xl font-bold'>
                    First, fill out your personal profile
                </p>
                <p className='text-md text-slate-500'>
                    We need to know more about you to ensure you are eligible to manage your nonprofit. You can access
                    and edit this information any time in the Starfish app.
                </p>
                <div className='flex flex-col flex-1 gap-3'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <Dropzone onDrop={(files) => this.onDrop(files, 'cover_photo')}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()} className='cursor-pointer'>
                                    <input {...getInputProps()} />
                                    <div>
                                        {hasCoverPic ? (
                                            <div
                                                style={{width: 600, height: 200}}
                                                className='bg-slate-50 overflow-hidden aspect-square flex flex-col items-center justify-center border border-gray-200 cursor-pointer hover:border-blue-500 rounded-lg'
                                            >
                                                <img
                                                    className=' object-contain object-cover'
                                                    style={{width: 600, height: 200}}
                                                    src={this.state.coverPhoto}
                                                    alt="cover-photo"
                                                />
                                                {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                            </div>
                                        ) : (
                                            <div
                                                style={{width: 600, height: 200}}
                                                className='bg-slate-50 aspect-square flex flex-col items-center justify-center border border-gray-200 cursor-pointer hover:border-blue-500 rounded-lg'
                                            >
                                                <SvgPlusCircle className={'text-blue-500'}/>
                                                <p className='text-xs mt-2'>
                                                    Add cover image
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <Dropzone onDrop={(files) => this.onDrop(files, 'profile_picture')}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()} className='cursor-pointer'>
                                    <input {...getInputProps()} />
                                    <div style={{marginTop: -80, marginLeft: 12}} className='mb-5'>
                                        {hasProfilePic ? (
                                            <div
                                                className='h-24 h-24 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                            >
                                                <img
                                                    className='h-24 h-24 object-contain bg-white aspect-square cursor-pointer  rounded-lg'
                                                    src={this.state.logoUrl}
                                                    alt="profile-pic"
                                                />
                                                {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                            </div>
                                        ) : (
                                            <div
                                                className='h-24 h-24 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                            >
                                                <SvgPlusCircle className={'text-blue-500'}/>
                                                <p className='text-xs mt-2'>
                                                    Add image
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <div className='flex flex-row justify-between gap-3'>
                            <div className='flex-1'>
                                <TextInput
                                    label={'First Name'}
                                    value={this.props.firstName}
                                    onChange={this.props.firstNameChanged}
                                    placeholder={'Enter first name'}
                                    type="default"
                                />
                            </div>
                            <div className='flex-1'>
                                <TextInput
                                    label={'Last Name'}
                                    value={this.props.lastName}
                                    onChange={this.props.lastNameChanged}
                                    placeholder={'Enter last name'}
                                    type="default"
                                />
                            </div>
                        </div>
                        <TextInput
                            label={'Bio'}
                            value={this.props.bio}
                            onChange={this.props.bioChanged}
                            placeholder={'ie. Student - Univ. of Michigan. I care about my school, helping animals get adopted, my faith, keeping the environment clean, and healthcare for children.'}
                            type="default"
                            textarea
                        />
                    </div>
                    <div className='flex flex-row flex-1 gap-3'>
                        <div className='flex-1'>
                            <TextInput
                                label={'City'}
                                value={this.props.city}
                                onChange={this.props.cityChanged}
                                placeholder={'Enter city name'}
                                type="default"
                            />
                        </div>
                        <div className='flex-[.5]'>
                            <p className='body3-bold text-gray-400'>
                                State
                            </p>
                            <SelectComponent
                                value={this.props.state}
                                setValue={this.props.stateChanged}
                                options={STATES.map((state) => {
                                    return {
                                        title: state,
                                        value: state
                                    }
                                })}
                                label={'State'}
                                light
                                toggleClassName={'w-100p'}
                                makeScrollable
                                position='top'
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ClaimNPOPersonalProfile);
