import {firebaseAuth} from "./firebase";
import {signInWithEmailAndPassword} from "firebase/auth";
import axios from "./axios";

export const login = async (email, password) => {
    return await signInWithEmailAndPassword(firebaseAuth, email, password)
        .then(async credential => {
            return credential.user
        })
        .catch(async error => {
            if (error.code === 'auth/invalid-email') {
                const uid = email;
                const config = {
                    params: {uid, email, password}
                };
                try {
                    const response = await axios.get("/auth/email", config);
                    return await login(response.data.email, password)
                } catch (err) {
                    throw new Error(err.request.status, err.message);
                }
            } else {
                throw new Error(error.code, error.message)
            }
        })
};

export const logout = async () => {
    return await firebaseAuth.signOut()
        .then(() => true)
        .catch(error => {
            console.log(error.code, error.message);
            return false
        })
};

export const signUp = async (email, uid, password, first_name, last_name) => {
    const body = {
        email,
        password,
        first_name,
        last_name,
        uid, 
    };
    try {
        const res = await axios.post("/auth/signup", body);
        return res.data;
    } catch (err) {
        throw new Error(err.request.status, err.message);
    }
};