const initialState = {
    user: null,
    authUser: {},
    mobile: false,
    selectedNonProfit: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                user: action.user
            }
        case "SET_AUTH_USER":
            return {
                ...state,
                authUser: action.user
            }
        case "CLEAR_USER":
            return {...initialState}
        case "SET_MOBILE":
            return {
                ...state,
                mobile:action.mobile
            }
        case "SET_SELECTED_NON_PROFIT":
            return {
                ...state,
                selectedNonProfit: action.nonProfit
            }
        default:
            return state;
    }
};
