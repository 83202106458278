import axios from "./axios";

export const getNonProfit = async (user, queried_non_profit_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {non_profit_id : queried_non_profit_id}
        };
        const res = await axios.get("/non_profits", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const editNonProfit = async (user, non_profit_id, params_to_edit) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            non_profit_id
        }
        const res = await axios.put("/non_profits", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const applyNonProfit = async (user, non_profit_id, role, message) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {non_profit_id, role, message}

        const res = await axios.post("/non_profits/apply", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}