import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M378-246L154-470l43-43 181 181 384-384 43 43-427 427z"/>
        </svg>
    )
}

export default SvgComponent
