import React, {Component} from 'react';
import HeaderStat from "../HeaderStat";
import SvgUsers from "../../icons/SvgUsers";
import {Button} from "../atoms/buttons/Button";
import Tooltip from "../atoms/Tooltip";
import SvgInstagram from "../../icons/SvgInstagram";
import SvgHelp from "../../icons/SvgHelp";
import TextInput from "../atoms/inputs/TextInput";
import SvgAlert from "../../icons/SvgAlert";

class CurrentBalanceWidget extends Component {


    render() {
        const bankAccount = this.props.bankAccount || {};
        const balance = this.props.balance || {};
        return (
            <div>
                <div
                    className='bg-white rounded-lg shadow-lg p-0 flex flex-row items-center justify-between p-6 gap-16'>
                    <div className='flex flex-row items-center gap-16'>
                        <p className='text-gray-800 text-2xl font-bold'>
                            Current Balance
                        </p>
                        <div className={'flex flex-row items-center gap-12'}>
                            <div className='flex flex-col gap-0'>
                                <div className='flex flex-row items-center gap-1'>
                                    <p className='heading4 text-gray-800'>
                                        ${balance.available_balance / 100 || 0}
                                    </p>
                                    <div>
                                        <Tooltip
                                            toggleComponent={
                                                <SvgHelp className={'w-4 h-4 mt-2'}/>
                                            }
                                            label={'Funds to be distributed to a connected bank account on the last business day of the month.'}
                                        />
                                    </div>
                                </div>
                                <p className='text-sm text-gray-500'>
                                    Available Funds
                                </p>
                            </div>
                            <div className='flex flex-col gap-0'>
                                <div className='flex flex-row items-center gap-1'>
                                    <p className='heading4 text-gray-800'>
                                        ${balance.pending_balance || 0}
                                    </p>
                                    <div>
                                        <Tooltip
                                            toggleComponent={
                                                <SvgHelp className={'w-4 h-4 mt-2'}/>
                                            }
                                            label={'Incoming funds to be made available within 5-7 days.'}
                                        />
                                    </div>
                                </div>
                                <p className='text-sm text-gray-500'>
                                    Pending Funds
                                </p>
                            </div>
                        </div>
                    </div>
                    {!this.props.connected &&
                        <div className='flex flex-row items-center gap-4'>
                            <div className='flex flex-row items-center gap-2 text-slate-500'>
                                <SvgAlert className={'w-4 h-4'}/>
                                <p className='text-md'>
                                    No active bank account linked
                                </p>
                            </div>
                        </div>
                    }
                    {this.props.connected &&
                        <div className='flex flex-row items-center gap-8'>
                            <div className='flex flex-row items-center gap-2'>
                                <img src={require('../../img/brand/bg.png')}
                                     className='w-8 h-8 rounded-full object-cover'/>
                                <div className='flex flex-col'>
                                    <p className={'text-md font-semibold'}>
                                        {bankAccount.account_type || "Checking"} - {bankAccount.last4}
                                    </p>
                                    <p className={'text-xs'}>
                                        {bankAccount.bank_name}
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <Button onClick={this.props.tryLinkAccount}>
                                    Edit Bank Account
                                </Button>
                                <Button variant={'secondary'} onClick={this.props.deleteAccount}>
                                    Unlink
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default CurrentBalanceWidget;
