import React, {Component} from 'react';
import HeaderStat from "../HeaderStat";
import SvgUsers from "../../icons/SvgUsers";
import SvgBarChart from "../../icons/SvgBarChart";
import {roundTo} from "../../utils/strings";

class ProfileOverviewWidget extends Component {

    render() {
        return (
            <div
                className='bg-white flex-1 rounded-lg shadow-lg p-6 px-6 flex flex-row gap-8 justify-between'>
                <div className='flex flex-row items-center gap-3'>
                    {this.props.data.name !== "All Nonprofits" ? 
                        <img className='w-14 h-14 border border-gray-200 rounded-lg' src={this.props.data.logo_url}
                            alt="Description of the image"/>
                    : null}
                    <p className='heading4'>
                        {this.props.data.name}
                    </p>
                </div>
                <div className='flex flex-row gap-4 items-center'>
                        <div className='p-2 rounded-full bg-blue-100'>
                            <SvgUsers/>
                        </div>
                        <div className='flex flex-col gap-0'>
                            <p className='heading4 text-gray-800'>
                                {this.props.data.total_followers}
                            </p>
                            <p className='text-sm text-gray-500'>
                                All Time Followers
                            </p>
                        </div>
                    </div>
                </div>             
        );
    }
}

export default ProfileOverviewWidget;
