import React, {PureComponent} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import LoginCard from "../components/LoginCard";
import SignUpCard from "../components/SignUpCard";

class AuthenticationPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'Login', // 'Login' or 'Signup'
        };
    }

    toggleTab = () => {
        this.setState(prevState => ({
            activeTab: prevState.activeTab === 'Login' ? 'Signup' : 'Login'
        }));
    };

    render() {
        const {activeTab} = this.state;
        const commonProps = {history: this.props.history, mobile: this.props.mobile, toggleTab: this.toggleTab};

        return (
            <div
                className="full-page flex flex-col items-center justify-center relative min-h-[100vh] bg-brand relative">
                <div className='burst z-[0]'/>
                <div className='burst2 z-[0]'/>
                <div className='mb-5'>
                    <img className='w-40' src={require('../img/brand/full_logo.png')}/>
                </div>
                <div className='relative z-10'>
                    <AnimatePresence mode={'wait'}>
                        {activeTab === 'Login' ? (
                            <motion.div
                                key="login"
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: 50}}
                                transition={{duration: 0.3, ease: "easeInOut"}}
                            >
                                <LoginCard {...commonProps} />
                            </motion.div>
                        ) : (
                            <motion.div
                                key="signup"
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: 50}}
                                transition={{duration: 0.3, ease: "easeInOut"}}
                            >
                                <SignUpCard {...commonProps} />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        );
    }
}

export default AuthenticationPage;
