import React, {Component} from 'react';
import SvgCheck from "../icons/SvgCheck";
import {NPOS} from "../utils/constants";
import SvgSearch from "../icons/SvgSearch";
import SvgPin from "../icons/SvgPin";
import {connect} from "react-redux";
import {getSuggestions, getDefaultSuggestions} from "../api/users";
import {searchNonProfits} from "../utils/typesense";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ClaimNPOSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            search: '',
            nonProfits: [],
        }
    }

    componentDidMount = async () => {
        this.updateSuggestions();
    }

    updateSuggestions = async () => {
        this.setState({loading: true})
        if (!!this.props.authUser) {
            const suggestions = await getSuggestions(this.props.authUser);
            this.setState({nonProfits: suggestions.non_profits, loading: false});
        } else {
            const suggestions = await getDefaultSuggestions(this.props.authUser);
            this.setState({nonProfits: suggestions.non_profits, loading: false});
        }
    }


    searchChange = (e) => {
        this.setState({search: e.target.value})
        searchNonProfits(e.target.value).then(nonProfitResults => {
            this.setState({nonProfits: nonProfitResults, loading: false});
        });
    }

    selectNPO = (ein) => {
    }

    render() {
        return (
            <div className='flex flex-col gap-3'>
                <p className='text-gray-800 text-2xl font-bold'>
                    What nonprofit do you want to manage?
                </p>
                <p className='text-md text-slate-500'>
                    Find the nonprofit profile you want to gain admin access for.
                </p>
                <div className=' relative'>
                    <input
                        onChange={this.searchChange}
                        value={this.state.search}
                        type="text"
                        className={'w-100p search-input w-full'}
                        placeholder="Search nonprofits..."
                    />
                    <SvgSearch className={'absolute text-slate-300'} style={{top: 10, left: 24, width: 24, height: 24}}/>
                </div>
                <div className='max-h-[45vh] min-h-[20vh] overflow-y-scroll flex flex-col gap-2 bg-slate-50 p-6 rounded-2xl'>
                    {(this.state.search.length === 0 && this.state.nonProfits.length === 0) &&
                        <div className='text-sm text-center py-12 text-slate-500'>
                            <p>
                                Search our list of over 1.3M non profits
                            </p>
                        </div>
                    }
                    {(this.state.search.length > 0 && this.state.nonProfits.length === 0) &&
                        <div className='text-sm text-center py-12 text-slate-500'>
                            <p>
                                No results for this search. Try something else.
                            </p>
                        </div>
                    }
                    {this.state.nonProfits.map((np) =>
                        <div className='p-4 flex flex-row br-12 search-result border-2 border-transparent hover:border-blue-500 rounded-lg' key={np.ein} onClick={() => this.props.setSelectedNp(np)}
                                style={{
                                    boxShadow: '0 4px 12px #00000030',
                                }}
                                onError={(e) => {
                                    e.target.src =
                                        "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                                    this.setState({imageError: true});
                                }}
                        >
                            <img src={np.logo_url} style={{
                                width: 64,
                                height: 64,
                                objectFit: "contain",
                                backgroundColor: "white",
                                border: '2px solid #f6f6f6'
                            }}
                                 className='rounded-lg bg-white-100'

                            />
                            <div className='col-plain ml-5' style={{textAlign: "left"}}>
                                <p className='body1-bold text-slate-800'>
                                    {np.name}
                                </p>
                                <p className='body2 black-70 text-slate-500'>
                                    {np.category}
                                </p>
                                <div className='flex flex-row items-center gap-1 mt-2 text-slate-500'>
                                    <SvgPin className={'primary'} style={{width: 16, height: 16}}/>
                                    <p className='body3-bold primary'>
                                        {np.city}, {np.state}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ClaimNPOSearch);
