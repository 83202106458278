import axios from 'axios';

const env = process.env.NODE_ENV;

let baseURL;
const devProxy = `http://localhost:5001/`;
const prodURL = `https://starfish-seethegood.uc.r.appspot.com/`;

if (env === 'production') {
    console.log("production");
    baseURL = prodURL;
} else {
    console.log("development");
    baseURL = devProxy;
}

export default axios.create({baseURL: baseURL + 'api'});
