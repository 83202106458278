import React, {Component} from 'react';

class HeaderStat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholder: false
        }
    }

    render() {
        return (
            <div className='flex flex-col gap-1'>
                <div>
                    <p className='heading3 text-gray-800'>
                        {this.props.dollars && '$'}{this.props.value}
                    </p>
                    <p className='text-sm text-gray-500'>
                        {this.props.label}
                    </p>
                </div>
                {/* <div className='flex flex-row items-center gap-2'>
                    <div className={`
                    ${
                        this.props.change === 0 ? 'bg-gray-100 text-gray-500' :
                            this.props.change > 0 ? 'bg-green-100 text-green-500' :
                                'bg-red-100 text-red-500'
                    }
                    text-sm font-bold px-2 rounded-lg`}>
                        16.2%
                    </div>
                    <p className='text-xs text-gray-500'>
                        vs last month
                    </p>
                </div> */}
            </div>
        );
    }
}

export default HeaderStat;
