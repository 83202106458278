import axios from "./axios";

export const editUser = async (user, params_to_edit) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit
        }
        const res = await axios.put("/users", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getUser = async (user) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/users", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getSuggestions = async (user) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/users/suggestions", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getDefaultSuggestions = async () => {
    try {
        const config = {
            // headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {}
        };
        const res = await axios.get("/users/default", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}