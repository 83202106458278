import React, {PureComponent} from 'react';
import {Button} from "./atoms/buttons/Button";
import TextInput from "../components/atoms/inputs/TextInput";
import Spinner from "../components/Spinner";
import {firebaseAnalytics, firebaseAuth} from "../api/firebase";
import {signUp} from "../api/authentication";
import {signInWithEmailAndPassword} from "firebase/auth";
import {hasLowercaseCharacter, hasUppercaseCharacter, hasDigit, validateEmail} from "../utils/strings";

class SignUpCard extends PureComponent {
    state = {
        username: "",
        email: "",
        password: "",
        error: "",
        loading: false,
        firstName: "",
        lastName: "",
    };

    onChangeUsername = (username) => {
        const strWOSpaces = username.replace(/\s+/g, '');
        const finalStr = strWOSpaces.replace(/\W/g, '');
        this.setState({username: finalStr.toLowerCase(), error: ""})
    }

    onChangeEmail = (email) => {
        this.setState({email: email.toLowerCase(), error: ""})
    }

    passwordChanged = e => this.setState({password: e.target.value, error: ""});

    onChangeFirstName = (firstName) => {
        this.setState({firstName, error: ""})
    }

    onChangeLastName = (lastName) => {
        this.setState({lastName, error: ""})
    }

    buttonPressed = () => {
        this.signUpPressed();
    };

    signUpPressed = async () => {
        let error;
        const passLength = this.state.password.length >= 6;
        const passLowercase = hasLowercaseCharacter(this.state.password);
        const passUppercase = hasUppercaseCharacter(this.state.password);
        const passDigit = hasDigit(this.state.password);

        if (!this.state.email) {
            error = "Please enter an email"
        } else if (!this.state.password) {
            error = "Please enter a password"
        } else if (!this.state.firstName || !this.state.lastName) {
            error = "Please enter your first and last name"
        } else if (!this.state.username) {
            error = "Please enter a username"
        } else if (!validateEmail(this.state.email)) {
            error = "Please enter a valid email address."
        } else if (!passLength) {
            error = "Password must be at least 6 characters long."
        } else if (!passUppercase) {
            error = "Password must have at least one uppercase letter."
        } else if (!passLowercase) {
            error = "Password must have at least one lowercase letter."
        } else if (!passDigit) {
            error = "Password must have at least one number."
        }

        if (error) {
            this.setState({error})
            return;
        }
        this.setState({processing: true})

        // try {
        await signUp(this.state.email, this.state.username, this.state.password, this.state.firstName, this.state.lastName);
        await signInWithEmailAndPassword(firebaseAuth, this.state.email, this.state.password);
        firebaseAnalytics.logEvent('web_sign_up_completed', {})

        console.log("SIGNED UP")
        // } catch (err) {
        //     this.setState({error: "Failure on sign up", processing: false})
        // }
    };

    render() {
        return (
            <form className='flex flex-col bg-white z-[2] shadow-2xl p-8 rounded-2xl mb-5 sm:w-[95vw] md:w-[440px]'
                  onSubmit={e => e.preventDefault()}>
                <div className="flex flex-col w-full gap-3">
                    <div className="flex flex-col gap-0 mb-5 ">
                        <div className='title1 mb-1'>
                            Create Your Account
                        </div>
                        <div className='body2 text-neutral-400'>
                            Sign up to get started!
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className='flex-row flex gap-2 flex-1'>
                            <TextInput
                                label={"First Name"}
                                name="firstName"
                                onChange={(e) => this.onChangeFirstName(e.target.value)}
                                value={this.state.firstName}
                                placeholder="First Name"
                                className={'flex-1 flex flex-col w-12'}
                            />
                            <TextInput
                                label={"Last Name"}
                                name="lastName"
                                onChange={(e) => this.onChangeLastName(e.target.value)}
                                value={this.state.lastName}
                                placeholder="Last Name"
                                className={'flex-1 flex flex-col w-12'}
                            />
                        </div>
                        <TextInput
                            label={'Username'}
                            value={this.state.username}
                            onChange={(e) => this.onChangeUsername(e.target.value)}
                            placeholder={'Enter username'}
                        />
                        <TextInput
                            label={'Email'}
                            value={this.state.email}
                            onChange={(e) => this.onChangeEmail(e.target.value)}
                            placeholder={'Enter email'}
                            type="email"
                        />
                        <TextInput
                            label={'Password'}
                            value={this.state.password}
                            onChange={this.passwordChanged}
                            placeholder={'•••••••••••••'}
                            type="password"
                        />
                    </div>
                    <div className='flex flex-col w-full'>
                        <div className='text-center mb-2'>
                            <p className="text-red-500 text-xs" style={{minHeight: 18, maxWidth: 300}}>
                                {this.state.error}
                            </p>
                        </div>
                        <Button variant='default' onClick={this.signUpPressed}>
                            {this.state.loading ? <Spinner/> : 'Sign Up'}
                        </Button>
                        <div className='flex flex-row text-sm items-center justify-center gap-2 mt-3'>
                            <p className='text-slate-500'>
                                Already have an account?
                            </p>
                            <p
                                onClick={this.props.toggleTab}
                                className='text-blue-500 font-semibold hover:text-blue-700 cursor-pointer'>
                                Log In
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default SignUpCard;
