import * as React from 'react';
import {Component, useEffect} from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";

function TooltipComponent(props) {
    const [controlledVisible, setControlledVisible] = React.useState(false);

    const offsetDelta = props.displayArrow ? 8 : 0

    const {
        getTooltipProps,
        getArrowProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip(
        {
            trigger: props.hover ? 'hover' : 'click',
            closeOnOutsideClick: true,
            closeOnTriggerHidden: true,
            visible: props.visible !== undefined ? props.visible : controlledVisible,
            onVisibleChange: props.toggle !== undefined ? props.toggle : setControlledVisible,
        },
        {
            placement: props.placement || 'right',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [props.offsetX || 0, (props.offsetY || 6) + offsetDelta]
                    }
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: props.node || document.body,
                        tether: true
                    }
                },
                {
                    name: 'flip',
                    options: {
                        flipVariations: false
                    }
                }
            ]
        }
    );

    useEffect(() => {
        const handleKeyDown = ({key}) => {
            if (key === 'Escape') {
                if (props.toggle !== undefined) {
                    props.toggle(false)
                } else {
                    setControlledVisible(false);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props]);

    return (
        <>
            {props.ToggleWrapper ?
                <props.ToggleWrapper ref={setTriggerRef}>
                    {props.ToggleComponent}
                </props.ToggleWrapper>
                :
                <div style={{cursor:props.disabled && "not-allowed"}}>
                    <div className={props.toggleClassName} ref={setTriggerRef} style={{pointerEvents:props.disabled && 'none'}}>
                        {props.ToggleComponent}
                    </div>
                </div>
            }
            {visible &&
                ReactDOM.createPortal(
                    props.label ?
                        <div
                            ref={setTooltipRef}
                            {...getTooltipProps({className: `${props.displayArrow ? 'tooltip-arrow-container body2-emphasized' : 'tooltip-container body2-medium'}`})}
                        >
                            {props.displayArrow && <div {...getArrowProps({className: 'tooltip-arrow-down'})}/>}
                            {props.label}
                        </div>
                        :
                        <div style={{zIndex: 3000}}
                             ref={setTooltipRef} {...getTooltipProps({className: "tooltip-container-blank"})}>
                            {props.ContentComponent}
                        </div>,
                    props.node || document.body
                )}
        </>
    );
}

TooltipComponent.propTypes = {
    ToggleComponent: PropTypes.object,
    ContentComponent: PropTypes.object,
    hover: PropTypes.bool,
    visible: PropTypes.bool,
    toggle: PropTypes.func,
    placement: PropTypes.oneOf(['auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'right-start', 'right-end', 'left', 'left-start', 'left-end']),
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
    node: PropTypes.node,
    label: PropTypes.string,
    displayArrow: PropTypes.bool,
    ToggleWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Component)]),
    toggleClassName: PropTypes.string
}

export default TooltipComponent;
