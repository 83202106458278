import React, {PureComponent} from "react";
import PropTypes from "prop-types";

class HorizontalDivider extends PureComponent {
    render() {
        return (
            <div className={`horizontal-divider ${this.props.className || ''}`}
                 style={{width: this.props.width, backgroundColor: this.props.color, flex: this.props.grow && 1}}
            />
        );
    }
}

HorizontalDivider.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string,
    grow: PropTypes.bool
}

export default HorizontalDivider
