import React, {Component} from 'react';
import HeaderStat from "../HeaderStat";
import {roundTo} from "../../utils/strings";


class HeaderStatsWidget extends Component {

    render() {
        return (
            <div className='bg-white rounded-lg items-center shadow-lg p-6 flex flex-row gap-5 justify-between'>
                <HeaderStat
                    label={'Total Donated'}
                    value={roundTo(this.props.data.total_donations)}
                    change={0}
                    dollars
                />
                <HeaderStat
                    label={'Total Recurring Amt'}
                    value={this.props.data.total_recurring}
                    change={0}
                    dollars
                />
                <HeaderStat
                    label={'Total # of Donations'}
                    value={this.props.data.num_donations}
                    change={0}
                />
                <HeaderStat
                    label={'Total Unique Donors'}
                    value={this.props.data.num_donors}
                    change={0}
                />
            </div>
        );
    }
}

export default HeaderStatsWidget;
