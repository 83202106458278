// WithNav.js (Stand-alone Functional Component)
import React from 'react';
import {Outlet} from 'react-router-dom';
import Navbar from "../Navbar";


const WithNav = ({nonProfits}) => {
    return (
        <>
            <Navbar nonProfits={nonProfits}/>
            <div>
                <Outlet/>
            </div>
        </>
    );
};


export default WithNav;
