export function addOrdinalSuffix(number) {
    var suffix = 'th';
    if (number % 10 === 1 && number % 100 !== 11) {
        suffix = 'st';
    } else if (number % 10 === 2 && number % 100 !== 12) {
        suffix = 'nd';
    } else if (number % 10 === 3 && number % 100 !== 13) {
        suffix = 'rd';
    }
    return number + suffix;
}

export function truncateString(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength - 3) + '...';
    }
};

export function rankColor(number) {
    if (number === 5) return 'bg-green-600'
    else if (number === 4) return 'bg-teal-500'
    else if (number === 3) return 'bg-yellow-500'
    else if (number === 2) return 'bg-orange-500'
    else if (number === 1) return 'bg-red-500'
    else return 'bg-neutral-300'
}

export const roundTo = (number, digits) => {
    if (number !== null && number !== undefined) {
        return parseFloat(number).toLocaleString('en-US', {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
        });
    } else {
        return '';
    }
};

export const hasLowercaseCharacter = string => {
    return /[a-z]/.test(string);
};

export const hasUppercaseCharacter = string => {
    return /[A-Z]/.test(string);
};

export const hasDigit = string => {
    return /[0-9]/.test(string);
};

export const hasSymbol = string => {
    return /[^0-9a-zA-Z]/.test(string);
};

export function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}