import React, {Component} from 'react';
import RangeBar from "../../components/charts/atoms/RangeBar";
import DonutChart from "../charts/DonutChart";
import {roundTo} from "../../utils/strings";


class DonutChartWidget extends Component {

    render() {
        const dollar = this.props.data[0]['x'] == "Challenge Donations" ? '$' : ''
        return (
            <div className='bg-white flex rounded-lg shadow-lg p-0 flex flex-col'>
                <p className='px-6 pt-6 text-gray-800 text-2xl font-bold'>
                    {this.props.title}
                </p>
                <div className='flex flex-col px-8'>
                    <DonutChart data={this.props.data}/>
                </div>
                <div className='flex flex-col gap-2 px-6 pb-6'>
                    <div className='flex flex-row items-center gap-2'>
                        <div style={{width: 12, height: 12, backgroundColor: '#1662F5'}} className='rounded-full'/>
                        <p className='text-lg font-bold text-gray-800'>
                            {this.props.data[0]['x']}: {dollar}{roundTo(this.props.data[0]['y'])}
                        </p>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                        <div style={{width: 12, height: 12, backgroundColor: '#F8969C'}} className='rounded-full'/>
                        <p className='text-lg font-bold text-gray-800'>
                            {this.props.data[1]['x']}: {dollar}{roundTo(this.props.data[1]['y'])}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default DonutChartWidget;
