import React, {Component} from 'react';
import PayoutListItem from "./PayoutListItem";
import SvgChevronDown from "../../icons/SvgChevronDown";
import Tooltip from "../atoms/Tooltip";

const COLUMNS = [
    {
        title: 'date',
    },
    {
        title: 'amount',
    },
    {
        title: 'distributed to',
    },
]

class PayoutHistoryWidget extends Component {
    state = {
        sortBy: 'date',
        ascending: true
    }

    toggleSort = (column) => {
        if (this.state.sortBy === column.title) {
            this.setState({ascending: !this.state.ascending})
        } else {
            this.setState({sortBy: column.title, ascending: true})
        }
    }

    render() {
        return (
            <div className='bg-white rounded-lg shadow-lg p-0 flex flex-col p-6'>
                <div className='flex flex-row items-center justify-between mb-2'>
                    <p className='text-gray-800 text-2xl font-bold'>
                        Payout History
                    </p>
                    <Tooltip
                        toggleComponent={
                            <div className={'bg-blue-100 text-blue-500 px-3 py-1 font-semibold text-sm rounded-full'}>
                                Next Payout: Dec 30
                            </div>
                        }
                        label={'Date at which the next distribution will occur. If no bank is linked, any current amount will roll to the next payout date.'}
                    />
                </div>
                <div
                    className='flex flex-row items-center gap-20 py-3 border-b border-b-neutral-200 text-xs font-semibold text-slate-400'>
                    {COLUMNS.map(column =>
                        <div
                            onClick={() => this.toggleSort(column)}
                            className={`flex-1 flex flex-row items-center gap-0 cursor-pointer ${this.state.sortBy === column.title && 'text-blue-500 font-bold'}`}>
                            <p style={{textTransform: "capitalize"}}>
                                {column.title}
                            </p>
                            {this.state.sortBy === column.title &&
                                <SvgChevronDown
                                    style={{transition: 'transform 150ms ease-in-out'}}
                                    className={`w-4 h-4 ${this.state.ascending ? 'rotate-180' : 'rotate-0'}`}/>
                            }
                        </div>
                    )}
                </div>
                {this.props.dummyData &&
                    <>
                        <PayoutListItem/>
                        <PayoutListItem/>
                        <PayoutListItem/>
                        <PayoutListItem/>
                        <PayoutListItem/>
                        <PayoutListItem/>
                        <PayoutListItem/>
                        <PayoutListItem/>
                        <PayoutListItem/>
                    </>
                }
                <div className='flex flex-col items-center justify-center'>
                    <p className='text-sm text-slate-700 my-20'>
                        No payouts yet. You'll be notified when your first payout is ready via email.
                    </p>
                </div>
            </div>
        );
    }
}

export default PayoutHistoryWidget;
