import React, {Component} from 'react';
import TextInput from "../atoms/inputs/TextInput";
import {Button} from "../atoms/buttons/Button";
import SvgPlusCircle from "../../icons/SvgPlusCircle";
import Dropzone from 'react-dropzone'
import GoBack from "../atoms/GoBack";
import { uploadDoc } from '../../api/firebase';
import { editNonProfit } from '../../api/nonProfits';
import SelectComponent from "../atoms/SelectComponent";
import {STATES} from "../../utils/constants";

class EditAccountWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            mission: "",
            numEmployees: 0,
            foundingYear: 0,
            websiteURL: "",
            address: "",
            city: "",
            zip: ""
        };
    }

    nameChanged = e => this.setState({name: e.target.value});
    missionChanged = e => this.setState({mission: e.target.value});
    numEmployeesChanged = e => this.setState({numEmployees: e.target.value});
    foundingYearChanged = e => this.setState({foundingYear: e.target.value});
    websiteURLChanged = e => this.setState({websiteURL: e.target.value});
    addressChanged = e => this.setState({address: e.target.value});
    cityChanged = e => this.setState({city: e.target.value});
    stateChanged = e => this.setState({state: e});
    zipChanged = e => this.setState({zip: e.target.value});


    setInfo = () => {
        const nonProfit = this.props.nonProfit || {};
        this.setState({
            name: nonProfit.name,
            mission: nonProfit.mission,
            numEmployees: nonProfit.num_employees,
            foundingYear: nonProfit.ruling_year,
            websiteURL: nonProfit.website_url,
            address: nonProfit.address1,
            city: nonProfit.city,
            state: nonProfit.state,
            zip: nonProfit.zip,
            logoUrl: nonProfit.logo_url,
            coverPhoto: nonProfit.cover_photo,
        })
    }

    componentDidMount = () => {
        this.setInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.nonProfit !== prevProps.nonProfit) {
            this.setInfo();
        }
    }

    onDrop = async (acceptedFiles, type) => {
        const url = await uploadDoc("non_profits/" + type, this.props.nonProfit.ein, acceptedFiles[0]);
        if (type === 'profile_picture') {
            this.setState({logoUrl: url});
        } else {
            this.setState({coverPhoto: url});
        }
    }

    saveChanges = async () => {
        const params = {
            name: this.state.name,
            mission: this.state.mission,
            num_employees: this.state.numEmployees,
            ruling_year: this.state.foundingYear,
            website_url: this.state.websiteURL,
            address1: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            logo_url: this.state.logoUrl,
            cover_photo: this.state.coverPhoto
        }
        await editNonProfit(this.props.user, this.props.nonProfit.ein, params);
        this.props.pullNpInfo();
    }

    render() {
        const hasProfilePic = !!this.state.logoUrl;
        const hasCoverPic = !!this.state.coverPhoto;
        const nonProfit = this.props.nonProfit || {};
        const saveEnabled = nonProfit.name !== this.state.name || nonProfit.mission !== this.state.mission || nonProfit.num_employees !== this.state.numEmployees || nonProfit.ruling_year !== this.state.foundingYear || nonProfit.website_url !== this.state.websiteURL || nonProfit.address1 !== this.state.address || nonProfit.city !== this.state.city || nonProfit.state !== this.state.state || nonProfit.zip !== this.state.zip || this.state.logoUrl !== nonProfit.logo_url || this.state.coverPhoto !== nonProfit.cover_photo;
        return (
            <div className='bg-white rounded-lg shadow-lg  p-0 flex flex-col items-center p-6 relative'>
                {/*<GoBack/>*/}
                <div className='flex flex-col items-center gap-3'>
                    <p className='text-gray-800 text-2xl font-bold mb-2 mt-2'>
                        Edit Public Facing Account
                    </p>
                    <div className='flex flex-col flex-1 gap-3'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <Dropzone onDrop={(files) => this.onDrop(files, 'cover_photo')}>
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()} className='cursor-pointer'>
                                        <input {...getInputProps()} />
                                        <div>
                                            {hasCoverPic ? (
                                                <div
                                                    style={{width: 600, height: 200}}
                                                    className='bg-slate-50 overflow-hidden aspect-square flex flex-col items-center justify-center border border-gray-200 cursor-pointer hover:border-blue-500 rounded-lg'
                                                >
                                                    <img
                                                        className=' object-contain object-cover'
                                                        style={{width: 600, height: 200}}
                                                        src={this.state.coverPhoto}
                                                        alt="cover-photo"
                                                    />
                                                    {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                                </div>
                                            ) : (
                                                <div
                                                    style={{width: 600, height: 200}}
                                                    className='bg-slate-50 aspect-square flex flex-col items-center justify-center border border-gray-200 cursor-pointer hover:border-blue-500 rounded-lg'
                                                >
                                                    <SvgPlusCircle className={'text-blue-500'}/>
                                                    <p className='text-xs mt-2'>
                                                        Add cover image
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            <Dropzone onDrop={(files) => this.onDrop(files, 'profile_picture')}>
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()} className='cursor-pointer'>
                                        <input {...getInputProps()} />
                                        <div style={{marginTop: -80, marginLeft: 12}} className='mb-5'>
                                            {hasProfilePic ? (
                                                <div
                                                    className='h-24 h-24 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                                >
                                                    <img
                                                        className='h-24 h-24 object-contain bg-white aspect-square cursor-pointer  rounded-lg'
                                                        src={this.state.logoUrl}
                                                        alt="profile-pic"
                                                    />
                                                    {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                                </div>
                                            ) : (
                                                <div
                                                    className='h-24 h-24 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                                >
                                                    <SvgPlusCircle className={'text-blue-500'}/>
                                                    <p className='text-xs mt-2'>
                                                        Add image
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            <TextInput
                                label={'Name'}
                                value={this.state.name}
                                onChange={this.nameChanged}
                                placeholder={'Enter name'}
                                type="default"
                            />
                            <TextInput
                                label={'Mission'}
                                value={this.state.mission}
                                onChange={this.missionChanged}
                                placeholder={'Enter mission statement'}
                                type="default"
                                textarea
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3'>
                            <TextInput
                                label={'Number of employees'}
                                value={this.state.numEmployees}
                                onChange={this.numEmployeesChanged}
                                placeholder={'Enter employees'}
                                type="number"
                            />
                            <TextInput
                                label={'Founding Year'}
                                value={this.state.foundingYear}
                                onChange={this.foundingYearChanged}
                                placeholder={'Enter year founded'}
                                type="number"
                            />
                            <TextInput
                                label={'Website URL'}
                                value={this.state.websiteURL}
                                onChange={this.websiteURLChanged}
                                placeholder={'Enter website URL'}
                                type="default"
                            />
                            <TextInput
                                label={'Address'}
                                value={this.state.address}
                                onChange={this.addressChanged}
                                placeholder={'Enter street address'}
                                type="default"
                            />
                            <TextInput
                                label={'City'}
                                value={this.state.city}
                                onChange={this.cityChanged}
                                placeholder={'Enter city name'}
                                type="default"
                            />
                            <div>
                                <p className='body3-bold text-gray-400'>
                                    State
                                </p>
                                <SelectComponent
                                    value={this.state.state}
                                    setValue={this.stateChanged}
                                    options={STATES.map((state) => {
                                        return {
                                            title: state,
                                            value: state
                                        }
                                    })}
                                    label={'State'}
                                    light
                                    toggleClassName={'w-100p'}
                                    makeScrollable
                                    position='top'
                                />
                            </div>
                            <TextInput
                                label={'ZIP'}
                                value={this.state.zip}
                                onChange={this.zipChanged}
                                placeholder={'Enter ZIP code'}
                                type="number"
                            />
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-center gap-2 mt-8'>
                        <Button variant={'default'} onClick={this.saveChanges} className='mt-2' disabled={!saveEnabled}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditAccountWidget;
